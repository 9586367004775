import React, { memo, useEffect, useRef } from "react";
import IMAGE_PLACEHOLDER from "assets/logo192.png";
import styles from "./index.module.scss";
import Skeleton from "react-loading-skeleton";

function ImageFullLoad({ src, parentProps, ...props }) {

  const parentRef = useRef();
  const imageRef = useRef();
  const skeletonRef = useRef();


  function handleChangeImage() {
    let img = new Image();
    img.onload = function () {
      if (!src || !imageRef?.current) return;
      imageRef.current.src = src;
      // ------------------------------------------------------ image
      imageRef.current.classList.add(styles["show"]);
      // imageRef.current.classList.remove(styles["hidden"]);
      // ------------------------------------------------------ skeleton
      skeletonRef.current.classList.remove(styles["show"]);
      // skeletonRef.current.classList.add(styles["hidden"]);
    };
    img.src = src;
  }

  useEffect(() => {
    // console.log({ current: imageRef.current }, imageRef.current.currentSrc);
    let didCancel;
    if (src === imageRef.current.currentSrc) return;
    // imageRef.current.src = IMAGE_PLACEHOLDER;
    // ------------------------------------------------------ image
    imageRef.current.classList.remove(styles["show"]);
    // imageRef.current.classList.add(styles["hidden"]);
    // ------------------------------------------------------ skeleton
    skeletonRef.current.classList.add(styles["show"]);
    // skeletonRef.current.classList.remove(styles["hidden"]);

    if (IntersectionObserver) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          return entry.isIntersecting && handleChangeImage();
        },
        { threshold: [0.009999999776482582], rootMargin: "0px" }
      );

      if (imageRef.current) {
        observer?.observe(imageRef.current);
      }

      return () => {
        didCancel = true;
        if (observer && observer.unobserve && imageRef.current) {
          observer.unobserve(imageRef.current);
        }
      };
    } else handleChangeImage();
  }, [src]);

  const { className, ...parentProp } = parentProps || {}
  return (
    <div className={[styles.box, "lazy-image-box", className]?.join(" ")} ref={parentRef} {...parentProp}>
      <div className={styles.skeleton} ref={skeletonRef}>
        <Skeleton style={{ height: "100%" }} />
      </div>
      <img ref={imageRef} {...props} />
    </div>
  )

}

export default memo(ImageFullLoad);
