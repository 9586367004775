import React, { createContext, useState, useContext } from 'react'
import { useEffect } from 'react'

const UuidContext = createContext()
export const useDiscount = () => useContext(UuidContext)

const name = "discount"

function getInitialState() {
    const data = localStorage.getItem(name)
    return data ? JSON.parse(data) : {
        code: false,
        amount: false,
        name: false
    }
    //  "e3a427f6-7821-4f2b-be22-012effb8de6b"
}

export default function UuidProvider({ children }) {
    const [data, setData] = useState(getInitialState)

    useEffect(() => {
        localStorage.setItem(name, JSON.stringify(data))
    }, [data])


    const initial = () => setData({
        code: false,
        amount: false,
        name: false

    })



    return (
        <UuidContext.Provider value={{ data, setData, initial }}>
            {children}
        </UuidContext.Provider>
    )
}