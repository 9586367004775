import React from "react";
import NavBar from "../navbar";
// import BottomNav from "../bottomNav";
// import Snowfall from "react-snowfall";
import style from "./index.module.scss"
import ErrorTime from "components/ErrorTime";
import { t } from "i18next";

export default function Layout(props) {
  return (
    <div>
      <div className={props.class}>
        {/* <NavBar /> */}
        {/* <BottomNav /> */}
        <div className={style.device}>
          {props.children}
        </div>
        <div className={style.checkDevice}>
          <ErrorTime message={t("deviceError")} />
        </div>
      </div>
    </div>
  );
}
