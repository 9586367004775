const baseStrings = {
	translation: {
		errors: {
			// "is-require": "{{author}} is require",
			"is-require": "This field is required",
			"is-not-valid": "{{author}} is not valid",
			"please-enter-your": "Please enter your {{author}}",
			"please-enter": "Please enter {{author}}",
			"please-select-your": "please select your {{author}}",
			"must-be": "{{author}} must be {{kind}}",
			min: "The lowest character is {{author}}",
			max: "The maximum number of characters is {{author}}",
		},
		home: " Home page ",
		menu: " Menu ",
		opportunity: " Special ",
		opportunityProducts: " Special Offers ",
		payment: " Payment ",
		back: "Back",
		yourName: " Full Name ",
		roomNumber: " Room Number ",
		deliveryInfo: " Guest Information ",
		yourDeliveryInfo: " Guest Information ",
		paymentInfo: " Payment Information ",
		productTotal: " Product Total ",
		serviceFee: " Service Fee ",
		total: " Total Amount ",
		online: "Online",
		card: "Credit Card At Delivery",
		myCart: " My Cart ",
		cash: " Cash ",
		note: "You can write your special instructions and notes here",
		delivery: " Delivery ",
		payment: " Payment ",
		orderNote: " Enter your order note ",
		completeOrder: " Complete Order ",
		enjoyYourMeal: " Enjoy your meal!",
		yourOrderReceived: " Your order has been received ",
		// completeOrder: " Your Order is Completed ",
		hello: " Hello. ",
		uuiError: " You can enter by simply scanning the QR code.",
		successful: " Successful ",
		productAddToCart: " Product Added to Cart!",
		addToBasket: " Add to Cart ",
		removeFromBasket: " Remove from Cart ",
		productTitle: " PERSONAL CARE ",
		itemNotFound: " Item Not Found ",
		completeTheOrder: " Please Complete the Order ",
		selectThePayment: " Please Select Payment Type ",
		paymentType: " Payment method ",
		orderLimitAuthor: " Order Limit {{author}} {{currency}}",
		orderLimit: " Order Limit ",
		dataNotFound: " No data found ",
		confirmOrder: " Tap to Complete the Order ",
		exchangeInfo: " If you pay in foreign currency, your change given in Turkish Lira",
		deliveryInfo: " Please make sure that you write your name and room number information completely so that your order can be delivered correctly..",
		weather: " Weather ",
		totalEuro: " Total Amount (Euros)",
		totalDollar: " Total Amount (Dollars)",
		totalTL: " Total Amount (TL)",
		diss: " Discount on All Products Special for Today!",
		continue: "Continue",
		cashCurrency: "Select Currency",
		other: "Other Categories",
		// preparing: "Your order is being prepared Enjoy your meal...",
		preparing: "Thank you! We've received your order. Please don't forget to check your inbox for the details.",
		notValid: "Enter Name Surname and Room Number",
		deviceError: "Tablet or mobile screen size is allowed. Allowed page size is less than 768 pixels",
		searchHome: "Search ...",
		notfound: "Data Not found",

		searchAuthor: "Search {{author}}",
		useDiscountCode: "Apply a discount code",
		discount: "Discount",
		discountAuthor: "Discount ({{author}})",
		products: "Products",
		append: "Append",
		code: "Code",
		codeCheck: "Apply",
		checkAuthor: "check {{author}}",
		"discount-note": "If you have a discount code, please enter",
		"discount-code-details": "The code you provided is {{code}}. The name of this discount code is {{name}} and it has a value of {{amount}}% discount",
		"back-to-menu": "Back to menu",
		bankErrorTitle: "Payment failed",
		bankErrorDescription: "There is a problem with payment. Please check your card information and try again",
		minimumCart: "minimum : {{author}}",
		orderNow: "Order now",
		AppointmentDaySelection: "Appointment Day Selection",
		paymentText: "Cash Payment is Not Accepted for Orders",
		backCart: "Back to cart",
		baklavaSpecialOrderPopup: "Baklava gift for your special orders",
		baklavaTitle: "taste the tradition",
		baklavaDescFirst: "Experience the joy of",
		baklavaDescSecond: "FREE BAKLAVA",
		baklavaDescThird: "with every order you place today!",
		apply: "Apply",
		networkDryTitle: "Precautions to Take Before Dry Cleaning",
		networkDrySubtitle:
			"This information clearly outlines what our guests should pay attention to before handing over their items for dry cleaning. Please, read carefully.",
		warning: "warning",
		networkDryWarning:
			"Products you deliver during the day will be delivered to you <underline>by 17:30 the next day</underline>. Products <underline> will not be delivered on the same day.</underline>",
		networkDryDesc1:
			"Label Inspection: Examine the care labels on your items. Ensure they are suitable for dry cleaning and follow the cleaning instructions. If you hand over items that are not suitable for dry cleaning, any potential issues that may arise will be the responsibility of the consumer.",
		networkDryDesc2:
			"Valuables Notification: Do not leave valuables (such as watches, necklaces, cash, etc.) in the pockets or hidden compartments of your items.",
		networkDryDesc3:
			"Delivery Time: Your items will be delivered to you by 5:30 PM the next day after they are received. If you have urgent needs, please indicate this in advance. (Specify in the order notes section.)",
		networkDryDesc4: "Delivery Conditions: Do not hand over your items with personal hangers and garment bags.",
		networkDryDesc5:
			"Stain Detection: If there are any stains on your items, identify them and inform the dry cleaner about these stains. Knowing what the stain is can help make the cleaning process more effective. (Specify in the order notes section.)",
		networkDryDesc6:
			"Special Instructions: If any part of your items is particularly sensitive or if you do not want a certain process applied, please clearly state this. (Specify in the order notes section.)",
		networkDryDesc7:
			"Wear and Damage Inspection: Before handing over your items, check for any wear, tears, or damage. Inform the dry cleaner of this to ensure extra precautions are taken. (Specify in the order notes section.)",
		networkDryDesc8:
			"Remove Accessories: If your items have removable accessories (such as belts, brooches, etc.), remove them before handing over your items.",
		networkDryDesc9:
			"Colour Fading: Check if your items are prone to color fading, and if so, inform the dry cleaner. (Specify in the order notes section.)",
		networkDryCheckedLabel: "I have read, understood, and agree to the instructions mentioned above.",
		tourTitle: "Free",
		tourDesc: "BOSPHORUS BOAT TOUR FOR EVERY €60 ORDER! ",
		tourButton: "Details",
		detailsPage: {
			title:
				"With this campaign, as soon as you reach the minimum order amount, you will win a Bosphorus tour with a boat. Place your order now to receive your free Bosphorus Tour gift. The campaign details are provided below.",
			desc1: "Only one free ticket is given for each €60 order.",
			desc2: "To win the gift, your cart total must be at least €60.",
			desc3: "The Bosphorus Tour includes a non-alcoholic fixed menu and various dance performances reflecting Turkish culture.",
			desc4: "The menu includes a main course, appetizers, dessert, and non-alcoholic beverages.",
			desc5: "The free Bosphorus Tour ticket can be used at the earliest the day after the order is placed.",
			desc6: "Reservation confirmation and tour details will be sent via email.",
			desc7: "To receive the free Bosphorus Tour ticket, the guest's name-surname, hotel name, room number, and valid contact information are required.",
			desc8: "To win a second ticket, you need to place another order of €60.",
		},
		tourPercentageLabel: "Complete Your Order to {{amount}} for Free BOSPHORUS TOUR.",
		completedTourBarText: "Tours details going to be send to your email.",
		completedSpecialOfferBarText: "{{remain}} left to win {{name}} for {{price}}!",
		specialOfferPercentageLabel: "You won {{name}} for {{price}}!",
		cartItem: "Cart item",
		cartItems: "Cart items",
		offers: "offers",
		offer: "offer",
	},
};

export const en = baseStrings;
