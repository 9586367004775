
import styles from "./index.module.scss"

const Backdrop = ({ onClick, fadeIn, reference }) => {

    return (
        <div
            className={[styles.backdrop, fadeIn ? styles.fadeIn : styles.fadeOut].join(" ")}
            // role="button"
            // tabIndex="0"
            onClick={onClick}
        />


    )
}

export default Backdrop;