import { ERRORS } from "enumeration";
import * as yup from "yup";

export const fieldNames = {
    code: "code",
};

export const validation = () =>
    yup.object().shape({
        [fieldNames.code]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.code)).label(fieldNames.code),
    });
