import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import Marquee from "react-fast-marquee";

const MarqueeComponent = ({ className, text }) => {
	const [isOverflowing, setIsOverflowing] = useState(false);
	const textRef = useRef(null);

	const checkOverflow = useCallback(() => {
		if (textRef.current) {
			const textWidth = textRef.current.scrollWidth;
			const containerWidth = textRef.current.clientWidth;
			console.log("* * * MarqueeComponent :", { textWidth, containerWidth });
			setIsOverflowing(textWidth > containerWidth);
		}
	}, [text]);

	const debounce = (func, delay) => {
		let debounceTimer;
		return function (...args) {
			const context = this;
			clearTimeout(debounceTimer);
			debounceTimer = setTimeout(() => func.apply(context, args), delay);
		};
	};

	const debouncedCheckOverflow = useCallback(debounce(checkOverflow, 500), [checkOverflow]);

	useEffect(() => {
		checkOverflow();
		window.addEventListener("resize", debouncedCheckOverflow);
		return () => window.removeEventListener("resize", debouncedCheckOverflow);
	}, [text]);
	console.log("* * * MarqueeComponent :", { isOverflowing, textRef });
	return (
		<div style={{ width: "100%", whiteSpace: "nowrap", overflow: "hidden" }} className={className} ref={textRef}>
			{isOverflowing ? (
				<Marquee speed={25} pauseOnHover>
					<span className="mx-2">{text}</span>
				</Marquee>
			) : (
				text
			)}
		</div>
	);
};

export default MarqueeComponent;
