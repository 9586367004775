import React from "react";
// import Modal from "react-modal";
import styles from "./style.module.scss";
import powered from "../../assets/powered.svg";
import { useTranslation } from "react-i18next";
import { getHotel } from "api/api";
import { useUuid } from "_context/uuid/uuidProvider";
import { useQuery } from "react-query";
import layer from "../../assets/baklava/bg2.png";
import bak from "../../assets/baklava/bak.png";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ImageFullLoad from "components/ImageFullLoad";
import { useCarts } from "_context/shoppingCart/cartProvider";
import { useNavigate } from "react-router-dom";

const TourPopup = ({ isOpen, onRequestClose }) => {
	const { data: uuid } = useUuid();
	// const { data, isError, error } = useQuery("hotel", () => getHotel(uuid), {
	// 	enabled: !!uuid,
	// });
	// const { addCart, removeItem, cart } = useCarts();
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();

	const handleAddCart = () => {
		// const newCampaignProduct = { ...data?.campaignProduct, oldPrice: data?.campaignProduct?.price, price: 0 };
		// if (data?.campaignProduct) addCart(data?.campaignProduct);
		onRequestClose(false);
	};

	return (
		<Modal
			// style={
			// 	{
			// 		// top: "0",
			// 		// alignItems: "center!important",
			// 		// transform: "translate(0%, 20%)"
			// 	}
			// }
			// isOpen={isOpen}
			// onRequestClose={onRequestClose}
			show={isOpen}
			onHide={handleAddCart}
			dialogClassName={styles.modal_dialog}
			aria-labelledby="example-custom-modal-styling-title"
			className={styles["overlay"]}
			contentClassName={styles["content"]}
			// overlayClassName={styles["overlay"]}
			// ariaHideApp={false}
			// size="lg"
		>
			<div className={styles["popup-content"]}>
				<div onClick={handleAddCart} className={styles["close-icon"]}>
					<span aria-hidden="true">&times;</span>
				</div>
				<div className={styles["popup-box"]}>
					<div className={styles["popup-wrap"]}>
						<div>
							<div className={[styles["popup-title"], "bold text-uppercase"].join(" ")}>
								<h1>{t("tourTitle")} </h1>
							</div>

							<div className={styles["baklava"]}></div>
						</div>
						<div>
							<div className={styles["text-box"]}>
								<p className={[styles["small"]].join(" ")}>
									{
										t("tourDesc")
										// t("Experience the joy of")
									}
								</p>
							</div>

							<Button
								className={[styles["button"], "mt-3"].join(" ")}
								// variant="danger"
								variant="light"
								onClick={() => {
									navigate("/details");
									handleAddCart();
								}}
								size="md">
								{t("tourButton")}
							</Button>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default TourPopup;
