import { useUtmSource } from "_context/utmSource/uuidProvider";
import FullScreenPopup from "components/languagePopup";
import { getHotel } from "api/api";
import { useQuery } from "react-query";
import { useUuid } from "_context/uuid/uuidProvider";
import ErrorTime from "components/ErrorTime";
import { useTranslation } from "react-i18next";
// import { useEffect } from "react";

const Middleware = ({ children }) => {
	const { t, i18n } = useTranslation();

	const { data: UtmData, setLanguage } = useUtmSource();
	// const queryClient = useQueryClient();

	const { data: uuid } = useUuid();
	const { data, isError, error, isLoading } = useQuery("hotel", () => getHotel(uuid), {
		enabled: !!uuid,
		retry: false,
		onSuccess: (data) => {
			if (!UtmData?.language && data?.defaultLanguage) {
				// alert("Change language");
				// console.log("* * * Middleware : ", { data, lang: UtmData?.language });
				i18n.changeLanguage(data?.defaultLanguage);
				setLanguage(data?.defaultLanguage);
			}
			// defaultLanguage
		},
	});
	// console.log("* * * Middleware * * *", !UtmData?.language, { data, isError, error, isLoading, UtmData });

	// useEffect(() => {
	// 	if (!UtmData?.language) return;
	// 	// queryClient.invalidateQueries("parentCategories");
	// }, [UtmData?.language]);
	if (isError) {
		return <ErrorTime message={error?.response?.data?.message} />;
	}
	// if (!UtmData?.language)
	// 	return (
	// 		<FullScreenPopup
	// 			isOpen={!UtmData?.language}
	// 			onChange={(e) => {
	// 				setLanguage(e);
	// 				queryClient.invalidateQueries("parentCategories");
	// 			}}
	// 		/>
	// 	);

	return (
		<>
			{/* <FullScreenPopup
				lang={UtmData?.language}
				isOpen={!UtmData?.language}
				onChange={(e) => {
					setLanguage(e);
					//
				}}
			/> */}
			{children}
		</>
	);
};

export default Middleware;

// import { useUtmSource } from "_context/utmSource/uuidProvider";
// import FullScreenPopup from "components/languagePopup";

// const Middleware = ({ children }) => {
//     const {
//         data: UtmData,
//         setLanguage
//     } = useUtmSource();

//     // useEffect(() => {
//     //     let pop_status = localStorage.getItem("pop_status");
//     //     // let pop_order = localStorage.getItem("pop_order");
//     //     if (!pop_status) {
//     //         setVisible(true);
//     //     }
//     // }, [visible]);

//     if (!UtmData?.language) return (
//         <FullScreenPopup
//             isOpen={!UtmData?.language}
//             onChange={(e) => setLanguage(e)}
//         />
//     )

//     return children
// }

// export default Middleware;
