import { ReactComponent as Search } from "./search.svg";
import { ReactComponent as Heart } from "./heart.svg";
import { ReactComponent as HeartFill } from "./heart-fill.svg";


const Icons = {
    Search,
    Heart,
    HeartFill,
}

export default Icons;