import React, { useState, useEffect, useMemo } from "react"

// import BackgrandCover from "../../BackgrandCover";
// import "./index.css"
import styles from "./index.module.scss";
import Backdrop from "reuse/Backdrop";

const ModalBox = ({ showModal, onHideModal, animationOff, fade, ...props }) => {

    const [modalHide, setModalHide] = useState(true)
    // function disableScrolling() {
    //   // if (modalHide) return
    //   var x = window.scrollX;
    //   var y = window.scrollY;
    //   window.onscroll = function () { window.scrollTo(x, y); };
    // }
    useEffect(() => {
        // const header = document.getElementById("header")
        const { body } = document
        // if (header) {
        //     // header.style.zIndex = "0"
        // }

        // console.log({ header })
        if (showModal) {
            // body.style.overflow = "hidden"
            setModalHide(!showModal)
            // window.scrollTo(0, window.scrollY + 30);

            // window.onscroll = function () { window.scrollTo(0, window.scrollY + 5); };
        } else {
            // window.scrollTo(0, window.scrollY - 30);
            // body.style.overflow = "auto"
        }
        // window.scrollTo(0, 0)
        // else if (header) {
        //     setTimeout(() => {
        //         // header.style.zIndex = "100"
        //         // body.style.overflow = "auto"
        //     }, 500)
        // }

        // window.addEventListener('touchstart', function (event) {
        //     if (event.touches[0].clientX < 50 && event.touches[0].clientY < 50) {
        //         alert('کاربر دکمه بازگشت را زده است');
        //         event.preventDefault()
        //     }
        // });
        // return () => {
        //     window.removeEventListener('touchstart', function (event) {
        //         if (event.touches[0].clientX < 50 && event.touches[0].clientY < 50) {
        //         }
        //     });
        // }
    }, [showModal])

    const endAnimation = () => {
        if (!showModal) {
            onHideModal()
            setModalHide(true)
        }
    }

    console.log("* * * ModalBox :", { modalHide });

    return (
        <div className={[styles.Div_modal_container_box, styles["bgUnset"]].join(" ")}>
            <div
                className={[styles["modal_container-box"], !showModal && styles["hidden"]].join(" ")}
            // style={{ display: modalHide ? "none" : "" }}
            >
                <div
                    // onAnimationEnd={endAnimation}
                    onTransitionEnd={endAnimation}
                    id={styles["subjectModal"]}
                    className={`${showModal ? styles["fadeIn"] : styles["fadeOut"]} ${animationOff ? styles["animation-off"] : ""} ${styles["fade-" + fade]}`} >
                    <div className={styles["subjectModal-box"]} >{props.children}</div>
                </div>
                <Backdrop onClick={onHideModal} fadeIn={!modalHide} />
            </div>
        </div>
    )
}

export default ModalBox
