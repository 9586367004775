import { useLocation } from "react-router-dom";

const useQueryString = (props) => {
  let queryStrings = { ...props };
  const location = useLocation();
  function decode(s) {
    let pl = /\+/g;
    return decodeURIComponent(s.replace(pl, " "));
  }
  (window.onpopstate = function () {
    let search = /([^&=]+)=?([^&]*)/g,
      match,
      query = location.search.substring(1);
    queryStrings = { ...queryStrings };
    while ((match = search.exec(query))) {
      queryStrings[decode(match[1])] = decode(match[2]);
    }
  })();
  return { ...queryStrings };
};

export default useQueryString;
