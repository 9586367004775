import React, { createContext, useState, useContext, useEffect } from "react";
import { handleAddProduct, handleChangeCount, handleRemoveProduct, handleTotal } from "./helperFunc";
import ModalMelo from "components/ModalMelo";
import ModalBox from "reuse/ModalBox";
import { useDiscount } from "_context/discount/discountProvider";
import { useQuery } from "react-query";
import { getHotel } from "api/api";
import { useUuid } from "_context/uuid/uuidProvider";

const CartContext = createContext();
// export const useCarts = () => useContext(CartContext);
export const useCarts = (initialParams = {}) => {
	const context = useContext(CartContext);
	return {
		...context,
		initialParams,
	};
};
function getInitialState() {
	const notes = localStorage.getItem("basket");
	return notes
		? JSON.parse(notes)
		: {
				shoppingCart: [],
				total: 0,
				object: {},
		  };
}

export default function CartProvider({ children, initialParams }) {
	const { data: uuid } = useUuid();
	const discountState = useDiscount();

	const { data: hotel } = useQuery("hotel", () => getHotel(uuid), {
		enabled: !!uuid,
	});

	const [data, setData] = useState(getInitialState);
	const [showMelo, setShowMelo] = useState(false);
	const [selectFinalProduct, setSelectFinalProduct] = useState(false);

	const handleSetMelo = (cart, meloAppointmentDate) => {
		// cart.meloAppointmentDate = meloAppointmentDate
		console.log("* * * handleSetMelo", { cart, meloAppointmentDate });
		setData(
			handleAddProduct({ state: data, data: cart, meloAppointmentDate, serviceCharge: hotel?.serviceCharge, discount: discountState?.data?.amount })
		);
		setShowMelo(false);
	};

	useEffect(() => {
		if (hotel) setData(handleTotal({ state: data, serviceCharge: hotel?.serviceCharge, discount: discountState?.data?.amount }));
	}, [hotel, discountState?.data?.amount]);

	useEffect(() => {
		localStorage.setItem("basket", JSON.stringify(data));
	}, [data]);

	// console.log("* * * CartProvider : ", { data, hotel, discountState, initialParams }, hotel?.serviceCharge);

	const addCart = (cart) => {
		// console.log("* * * CartProvider : ", { cart });

		// if (cart?.isCampaign) {
		// 	cart = { ...cart, oldPrice: cart?.price, price: 0 };
		// }
		if (cart.isMeloProduct) {
			setSelectFinalProduct(cart);
			setShowMelo(true);
		} else {
			const isToCart = data?.object?.[cart?.id];
			if (isToCart) addCount(cart, 1);
			else setData(handleAddProduct({ state: data, data: cart, serviceCharge: hotel?.serviceCharge, discount: discountState?.data?.amount }));
		}
	};
	const addCount = (cart, count) => {
		// console.log("* * * CartProvider :", { cart, count });
		if (count == undefined) count = cart?.count + 1;
		if ((cart?.promotionCodes || cart?.isCampaign) && count > 1) count = 1;
		setData(handleChangeCount({ state: data, cart, count, serviceCharge: hotel?.serviceCharge, discount: discountState?.data?.amount }));
	};
	const removeItem = (cart) =>
		setData(handleRemoveProduct({ state: data, cart, serviceCharge: hotel?.serviceCharge, discount: discountState?.data?.amount }));

	const initial = () =>
		setData({
			shoppingCart: [],
			total: 0,
			object: {},
		});

	return (
		<CartContext.Provider value={{ cart: data, addCart, addCount, removeItem, initial }}>
			{children}
			<ModalMelo
				{...{
					show: showMelo,
					setShow: setShowMelo,
					handleSubmit: handleSetMelo,
					data: selectFinalProduct,
				}}
			/>
		</CartContext.Provider>
	);
}
