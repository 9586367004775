import React, { createContext, useState, useContext } from "react";
import { useEffect } from "react";

const CategoryContext = createContext();
export const useCategory = () => useContext(CategoryContext);

const name = "category";
const initialState = {
  parent: false,
  child: false,
};
function getInitialState() {
  const data = localStorage.getItem(name);
  return data ? JSON.parse(data) : { ...initialState };
}

export default function CategoryProvider({ children }) {
  const [data, setData] = useState(getInitialState);

  useEffect(() => {
    localStorage.setItem(name, JSON.stringify(data));
  }, [data]);

  const initial = () =>
    setData({
      parent: false,
      child: false,
    });

  const setParent = (x) => setData((p) => ({ ...p, parent: x }));
  const setChild = (x) => setData((p) => ({ ...p, child: x }));

  return <CategoryContext.Provider value={{ data, setParent, setChild, initial }}>{children}</CategoryContext.Provider>;
}
