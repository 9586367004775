import React from "react";
import { Col, Row } from "react-bootstrap";
import styles from "./privacy.module.scss";
import NavBar from "components/navbar";
import BottomNav from "components/bottomNav";



export default function Privacy() {
  return (
    <>

      <NavBar />
      <div className={styles.container}>
        <Row >


          <Col>
            <h1 className={styles.headline}>GİZLİLİK VE GÜVENLİK POLİTİKASI</h1>

            <p className={styles.paragraph}>
              Bu Gizlilik ve Güvenlik Politikası, Papyon Otel İkram ve Catering Hizmetleri Anonim Şirketi’nin (“Papyon”)
              faaliyet gösterdiği, https://room-services.net/ isimli elektronik ticaret platformunda sunulan mal ve hizmet
              satışından yararlanmak isteyen müşteri adayları ve müşterileri kapsamaktadır. Papyon, işbu platform üzerinden
              verilecek siparişlerde müşteriden yalnızca ad-soyad ve oda numarası bilgilerini talep etmekte olup tüm
              müşterilerinin gizliliğini korur ve bu doğrultuda müşterinin izni olmadıkça yahut yasal bir süreç bulunmadığı
              müddetçe üçüncü şahıslar ile bilgi paylaşımında bulunmamayı taahhüt ederek verdiği tüm hizmetlerde olduğu gibi
              güvenli hizmet politikasından şaşmadan faaliyetlerini gerçekleştirmeyi amaçlar. Papyon müşterilerinin sipariş
              esnasında paylaştığı kişisel bilgilerini yalnızca eksiksiz ve talebe uygun nitelikte hizmet verebilmek amacıyla
              saklar ve Anayasa’nın 20. Maddesinin 3. Fıkrası uyarınca müşterinin kişisel verilerini ancak kanunda öngörülen
              hallerde veya kişinin açık rızası bulunduğu takdirde işleyeceğini belirtilerek kişisel verilerin korunması
              hususunda güvence sağlar. Müşterinin ödeme ekranında paylaştığı tüm veriler SSL Secure güvenlik ağı ile
              şifrelenerek sisteme aktarılmakta ve bu sayede dışarıdan bu bilgilerin alınması, okunması ya da kullanılması
              mümkün olmamaktadır. Şifrelenerek ödeme sistemine aktarılan bu bilgiler arasında yer alan kredi kartı numarası,
              son kullanma tarihi, kart tipi ve CVV2 güvenlik numarası kesinlikle Papyon personeli tarafından görülememekte
              olup bu bilgilerin hiçbiri Papyon tarafından saklanmamaktadır. Papyon, işbu "Gizlilik ve Güvenlik Politikası"
              hükümlerini dilediği zaman sitede yayınlamak yayınlamak suretiyle değiştirebilir. Gizlilik ve Güvenlik
              Politikası hükümleri değiştiği takdirde, yayınlandığı tarihte yürürlük kazanır.
              <br />
              Gizlilik ve güvenlik politikamız ile ilgili her türlü soru ve önerileriniz için info@papyonkitchen.com adresine
              e-mail gönderebilir ya da 0 (535) 833 36 25 numaralı destek hattı aracılığı ile iletişim sağlayabilirsiniz.
            </p>
          </Col>

        </Row>

      </div>
    </>
  );
}
