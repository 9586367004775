import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import styles from "./index.module.scss";
import NavBar from "components/navbar";
import BottomNav from "components/bottomNav";
import banner from "assets/tour/ONAY.png";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function Details() {
  const { t } = useTranslation();
  return (
    <>
      <NavBar />
      <div className={styles.container}>
        <Row>
          <Col>
            <div className={styles.banner}>
              <img src={banner} />
            </div>
            <p className={styles.paragraph}>{t("detailsPage.title")}</p>

            <ul className={[styles.paragraph, styles.list].join(" ")}>
              <li>
                <p>{t("detailsPage.desc1")}</p>
              </li>
              <li>
                <p>{t("detailsPage.desc2")}</p>
              </li>
              <li>
                <p>{t("detailsPage.desc3")}</p>
              </li>
              <li>
                <p>{t("detailsPage.desc4")}</p>
              </li>
              <li>
                <p>{t("detailsPage.desc5")}</p>
              </li>
              <li>
                <p>{t("detailsPage.desc6")}</p>
              </li>
              <li>
                <p>{t("detailsPage.desc7")}</p>
              </li>
              <li>
                <p>{t("detailsPage.desc8")}</p>
              </li>
            </ul>
          </Col>
          <div className={styles.buttonWrap}>
            <Link to={"/"}>{t("continue")}</Link>
          </div>
        </Row>
      </div>
    </>
  );
}
