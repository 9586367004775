import { useMemo } from "react"
import styles from "../index.module.scss"
import moment from "moment";
import { useTranslation } from "react-i18next";
// import moment from "jalali-moment";


const DayComponent = ({ data, value, onSelect, max }) => {


    const { t, i18n } = useTranslation();

    const weekData = useMemo(() => {
        const date = []

        const today = moment();

        for (let i = 0; i < 7; i++) {
            const currentDate = today.clone().add(i, 'days');

            const dayOfWeek = currentDate.format('ddd');
            const dayOfMonth = currentDate.date();
            const month = currentDate.format('MMMM');

            date.push({
                label: dayOfWeek,
                number: dayOfMonth,
                month: month,
                date: currentDate.toString(),
                isMax: i == max,
                disabled: i >= max
            });
        }
        if (!value && date?.length) onSelect(date[0])
        return date;
    })

    console.log("* * * DayComponent ", { weekData, max });

    const handleSelectWeek = (param) => {
        console.log("* * * DayComponent (handleSelectWeek) ", { param });
        onSelect(param)
    }




    return (
        <div className={styles.weekBox}>

            {
                weekData?.map((x, i) => {
                    return (
                        <div
                            className={
                                [
                                    styles.weekItem,
                                    x.number == value?.number && styles.active,
                                    x.disabled && styles.disable,
                                ].join(" ")
                            }
                            key={x.label}
                            onClick={() => !x.disabled && handleSelectWeek(x)}>

                            <div className={styles.week}>
                                {x?.label}
                            </div>

                            <div className={[styles.numberDate].join(" ")}>
                                {x?.number}

                            </div>
                            <div className={styles.border}>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default DayComponent;