import React from "react";

export default function About() {
  return (
    <div className="container">
      <p>
        Papyon Room Service Solutions 2021 yılında İstanbul'da kurulmuştur. Kurulduğu günden bu yana, +350 otel ile
        işbirliği yaparak müşterilerine güvenilir, pratik ve hızlı hizmet vermeyi vizyon edinmiştir.
        <br />
        <br />
        Papyon Room Service Solutions Contact Gulbahar Mah. Oya Sk. Tumer Plaza No:7/2 Sisli/Istanbul +90 212 909 05 44
      </p>
    </div>
  );
}
