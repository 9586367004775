import styles from "./style.module.scss";
import giftIcon from "assets/tour/box.png";
import boatIcon from "assets/tour/boat.png";
import { calcPercentNumber } from "utils/calculators";
import { SYMBOLS_DATA_OBJECT } from "enumeration";
import { getHotel } from "api/api";
import { useQuery } from "react-query";
import { useUuid } from "_context/uuid/uuidProvider";
import { useCarts } from "_context/shoppingCart/cartProvider";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useDiscount } from "_context/discount/discountProvider";
import Decimal from "decimal.js";
import configs from "../../config";

const TourCompletePercentage = () => {
	const { t } = useTranslation();

	const discountState = useDiscount();
	const { data: uuid } = useUuid();
	const { data: hotel } = useQuery("hotel", () => getHotel(uuid), {
		enabled: !!uuid,
	});
	const { cart } = useCarts();
	const showTour = configs.supportedRegionTour.includes(hotel?.region?._id);
	if (!showTour) return <></>;
	const menuCurrency = hotel?.region?.menuCurrency;
	let currentCurrency = SYMBOLS_DATA_OBJECT[menuCurrency];
	let symbol = currentCurrency?.label;
	let showTotal = cart.withDiscountPrice || cart.totalWithService;

	// if (discountState?.data?.amount) showTotal = showTotal.sub(showTotal.div(100).mul(discountState?.data?.amount));
	showTotal = +showTotal;
	if (showTotal < 30) return <></>;

	let percent = +calcPercentNumber(60, showTotal);
	percent = percent > 100 ? 100 : percent;
	// console.log("* * * TourCompletePercentage :", { percent });
	showTotal = Math.floor(showTotal);
	showTotal = showTotal >= 60 ? 60 : showTotal;
	const isComplete = percent >= 100;

	// hotel?.serviceCharge
	return (
		<Link to={"/details"} className={[styles.wrap, showTour ? styles.show : styles.hide].join(" ")}>
			<div className={[styles.container].join(" ")}>
				<div className={styles.box}>
					<div className={styles.head}>
						<div className={styles.amount}>{`${showTotal}${symbol}`}</div>
						<div className={styles.text}>{isComplete ? t("completedTourBarText") : t("tourPercentageLabel", { amount: `60${symbol}` })}</div>
						<div className={styles.amount}>{`60${symbol}`}</div>
					</div>
					<div className={styles.body}>
						<div className={styles.radialBox}>
							<div className={styles.radialBoxActive} style={{ width: `${percent}%` }}></div>
							{
								<img
									className={styles.boatIcon}
									style={{ left: `${percent - 10 || 0}%`, visibility: !isComplete ? "visible" : "hidden" }}
									src={boatIcon}
									alt={"gift"}
								/>
							}
						</div>
						<div className={styles.giftBox}>
							<img className={styles.giftIcon} src={giftIcon} alt={"gift"} />
						</div>
					</div>
				</div>
			</div>
		</Link>
	);
};

export default TourCompletePercentage;
