import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function ModalLayout({ title, children, show, setShow, className }) {
	return (
		<Modal
			show={show}
			onHide={() => setShow(false)}
			// dialogClassName="modal-90w"
		>
			<Modal.Header closeButton>
				<Modal.Title className={className?.title}>{title}</Modal.Title>
			</Modal.Header>
			<Modal.Body>{children}</Modal.Body>
		</Modal>
	);
}

export default ModalLayout;
