import { useMemo, useEffect, useState } from "react";
import { Badge, Button } from "react-bootstrap";
import { changePriceFormat, numberWithCommas } from "utils/calculators";
import { pageView } from "../../GTM";
import "./style.scss";

import { useCarts } from "_context/shoppingCart/cartProvider";
import ModalProduct from "components/ModalProduct";

const { useTranslation } = require("react-i18next");

const ProductCard = ({ handleRemoveCart, handleAddCart, data, isToCart, symbol }) => {
	const { t, i18n } = useTranslation();
	const [show, setShow] = useState(false);

	const isTranslate = useMemo(() => {
		const objData = {};
		data?.translations?.forEach((x) => (objData[x?.language] = x));

		return objData;
	}, [data]);
	const { addCount, cart, removeItem, addCart } = useCarts();

	const textTranslate = isTranslate[i18n?.language];
	//   console.log({ isTranslate, textTranslate }, i18n?.language);

	useEffect(() => {
		pageView(window?.location?.pathname);
	}, []);

	const limitText = (text, limit) => {
		if (text?.length <= limit) {
			return text;
		}
		return text?.slice(0, limit) + "...";
	};

	return (
		<div className="col-12 col-md-6 cursor-pointer">
			<div className="productCard d-flex flex-row">
				<div className="productCardImageBox" onClick={() => setShow(true)}>
					<img src={data?.images} className="CardImage" alt={data?.name} />
				</div>
				<div className="card-body radius">
					<h4 className="cardText">{limitText(textTranslate?.name || data?.name, 28)}</h4>

					<p>{limitText(textTranslate?.shortDesc || data?.shortDesc, 65)}</p>
					<p>{textTranslate?.desc || data?.desc}</p>
					<div className="d-flex flex-row  mt-2">
						{/* {data?.oldPrice ? (
							<h4 className="orgPrice">
								{numberWithCommas(changePriceFormat(data?.oldPrice))} {symbol}
							</h4>
						) : (
							""
						)} */}
						<h4 className="price">
							{numberWithCommas(changePriceFormat(data?.price))} {symbol}
						</h4>
					</div>
					{isToCart ? (
						<span className="addBasketButtons d-flex align">
							{data?.count > 1 ? (
								<button
									type="button"
									className="left dec quantityButtonMinus comeTop"
									id="adetEksi"
									data-urunid={19}
									// disabled={data?.isCampaign}
									// onClick={() => !data?.isCampaign && addCount(data, data?.count - 1)}
									onClick={() => addCount(data, data?.count - 1)}>
									<i className="fa-solid fa-minus" />
								</button>
							) : (
								<button type="button" className="left dec quantityButtonMinus comeTop" id="adetEksi" data-urunid={19} onClick={() => handleRemoveCart(data)}>
									<i className="fa-solid fa-minus" />
								</button>
							)}

							<Button className="quantityButton">{data?.count}</Button>
							<button type="button" className=" right inc quantityButtonPlus" id="adetArti" data-urunid={19} onClick={() => addCount(data)}>
								{" "}
								<i className="fa-solid fa-plus" />
							</button>
						</span>
					) : (
						<Button onClick={() => handleAddCart(data)} className="float-end addBasketButton"></Button>
					)}
					{/* <Button
            onClick={() => addCount(data)}
            className="float-end addBasketButton"
          >
            1
          </Button>
          <Button
            onClick={() => addCount(data, data.count - 1)}
            className="float-end addBasketButton"
          >
            1
          </Button> */}
				</div>
			</div>
			<ModalProduct {...{ symbol, show, setShow, handleRemoveCart, handleAddCart, addCount, data, changePriceFormat, textTranslate, isToCart }} />
		</div>
	);
};

export default ProductCard;
