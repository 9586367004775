import React, { createContext, useState, useContext } from 'react'
import { useEffect } from 'react'

const DeliveryContext = createContext()
export const useDelivery = () => useContext(DeliveryContext)

function getInitialState() {
    const notes = localStorage.getItem('delivery')
    return notes ? JSON.parse(notes) : {
        roomNumber: "",
        name: "",
    }
}

export default function DeliveryProvider({ children }) {
    const [data, setData] = useState(getInitialState)

    useEffect(() => {
        localStorage.setItem('delivery', JSON.stringify(data))
    }, [data])

    console.log({ data });

    const onChange = (e) => {
        setData(x => ({ ...x, [e.target.name]: e.target.value }))
    }

    const setRoomNumber = (data) => {
        setData(x => ({ ...x, roomNumber: data, staticRoomNumber: data }))
    }

    const initial = (force) => setData({
        ...(force ? {
            staticRoomNumber: "",
            roomNumber: "",
        } : {
            staticRoomNumber: data.staticRoomNumber,
            roomNumber: data.staticRoomNumber,
        }),
        name: "",
    })


    return (
        <DeliveryContext.Provider value={{ data, setData, setRoomNumber, onChange, initial }}>
            {children}
        </DeliveryContext.Provider>
    )
}