import ProductCard from "components/isCartCard";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styles from "../index.module.scss";

const SpecialOffer = ({ hotel, handleRemoveCart, symbol }) => {
	const { t } = useTranslation();
	useEffect(() => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	}, []);
	if (hotel.campaignProduct) {
		hotel.campaignProduct.isCampaignOffer = true;
		hotel.campaignProduct.oldPrice = 7;
	}
	return (
		<div className={[styles.specialOffer, "mt-3 mb-3"].join(" ")}>
			<div className="d-flex align-items-center mb-3">
				<div className={"border-top w-50 "}></div>
				<div className={[styles.offerTitle, "px-2 text-danger text-nowrap text-uppercase"].join(" ")}>{t("offer")}</div>
				<div className={"border-top w-50"}></div>
			</div>
			<div className="px-4">
				<ProductCard small data={{ data: hotel?.campaignProduct }} handleRemoveCart={handleRemoveCart} symbol={symbol} />
			</div>
		</div>
	);
};

export default SpecialOffer;
