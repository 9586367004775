import React from "react";

export default function DistanceSelling() {
  return (
    <div className="container">
      <h3>MESAFELİ SATIŞ SÖZLEŞMESİ</h3>
      <p>
        Papyon Otel İkram ve Catering Hizmetleri Anonim Şirketi - “PAPYON” ile müşteri arasındaki Mesafeli Satış
        Sözleşmesi’dir.
      </p>

      <h3>MADDE 1: TARAFLAR</h3>
      <p>
        <strong>SATICI</strong>
        <br />
        Unvan: PAPYON OTEL İKRAM VE CATERİNG HİZMETLERİ ANONİM ŞİRKETİ
        <br />
        Açık Adresi: Gülbahar Mahallesi Oya Sk. Tümer Plaza Apt. No: 7/2 Şişli/İstanbul
        <br />
        Tel: 0 535 833 36 25
        <br />
        E-posta: info@papyonkitchen.com
        <br />
        Mersis: 0721080277700001
        <br />
        Vergi No: 7210802777
        <br />
        Müşteri Hizmetleri Tel: 0 535 833 36 25
      </p>

      <p>
        <strong>MÜŞTERİ/TÜKETİCİ</strong>
        <br />
        Adı – Soyadı:
        <br />
        Telefon:
        <br />
        “MÜŞTERİ/TÜKETİCİ”: İşbu Sözleşme uyarınca siparişin teslim edileceği Tüketici ya da Tüketici dışındaki gerçek
        kişiyi ifade eder.
      </p>

      <h3>MADDE 2: KONU</h3>
      <p>
        2.1. İşbu Mesafeli Satış Sözleşmesi (“Sözleşme”) 6502 Sayılı Tüketicinin Korunması Hakkında Kanun'a (“Kanun”) ve
        Mesafeli Sözleşmeler Yönetmeliği'ne (“Yönetmelik”) ve 23.08.2022 tarihli ve 31932 sayılı Resmi Gazete’de
        yayınlanan Mesafeli Sözleşmeler Yönetmeliğinde Değişiklik Yapılmasına Dair Yönetmelik’e uygun olarak
        düzenlenmiştir. Sözleşme'nin tarafları işbu Sözleşme tahtında Kanun’dan ve Yönetmelik’ten kaynaklanan yükümlülük
        ve sorumluluklarını bildiklerini ve anladıklarını kabul ve beyan ederler.
      </p>

      <p>
        2.2. İşbu Sözleşmenin konusunu; Müşteri’nin, PAPYON OTEL İKRAM VE CATERİNG HİZMETLERİ ANONİM ŞİRKETİ
        (“PAPYON”)'a ait www.room-services.net
      </p>
      <p>
        <strong> 2.3. </strong>Listelenen ve sitede ilan edilen fiyatlar satış fiyatıdır. İlan edilen fiyatlar ve
        vaatler güncelleme yapılana ve değiştirilene kadar geçerli olup süreli olarak ilan edilen fiyatlar ise
        belirtilen süre sonuna kadar geçerliliğini koruyacaktır.
      </p>

      <h3>MADDE 3: SÖZLEŞME’NİN KURULMASI</h3>

      <p>
        <strong> 3.1. </strong> Müşteri sözleşmeyi okuduğunu, anladığını, haklarının ve yükümlülüklerinin bilincinde
        olduğunu, sözleşme kapsamında yer alan işlemlerin kendi menfaatine uygun olduğu konusunda tam bir kanaate
        vardığını ve tüm sözleşme şartlarını kendi özgür iradesi ile kabul eder.
      </p>
      <p>
        <strong> 3.2. </strong> Müşteri, sözleşmenin hükümlerinin haksız şart sayılabilecek bir özellik taşımadığını,
        menfaatler dengesi bakımından bir haksızlık olmadığını kabul eder.
      </p>

      <h3>MADDE 4: SÖZLEŞME KONUSU MAL VE HİZMETİN NİTELİĞİNE VE TESLİMATINA İLİŞKİN BİLGİLER</h3>

      <p>
        <strong> 4.1. </strong> Siparişe konu malın/ürün/ürünlerin/ hizmetin temel özelliklerini (türü, miktarı,
        marka/modeli, rengi, adedi) Papyon’a ait internet sitesinde yayınlanmaktadır. Papyon tarafından kampanya
        düzenlenmiş ise ilgili ürünün temel özelliklerini kampanya süresince inceleyebilirsiniz.
      </p>

      <p>
        <strong> 4.2. </strong> Listelenen ve sitede ilan edilen fiyatlar satış fiyatıdır. İlan edilen fiyatlar ve
        vaatler güncelleme yapılana ve değiştirilene kadar geçerlidir. Süreli olarak ilan edilen fiyatlar ise belirtilen
        süre sonuna kadar geçerlidir. Sözleşme konusu mal ya da hizmetin tüm vergiler dâhil satış fiyatı aşağıda
        gösterilmiştir. Ürün Açıklaması AdetBirim Fiyatı Ara Toplam (KDV Dahil) <br /> Servis Ücreti <br /> Toplam :{" "}
        <br />
        Ödeme Şekli <br /> Teslimat Adresi <br /> Teslim Edilecek kişi <br /> Fatura Adresi <br /> Sipariş Tarihi <br />{" "}
        Teslimat tarihi <br /> Siparişinize ilişkin bilgi almak ve şikayetlerinizi iletmek için 0 535 833 36 25
        numaradan ücretsiz olarak arayabilir ve info@papyonkitchen.com mail adresine yazılı bildirimde bulunabilirsiniz.
      </p>

      <h3>MADDE 5: GENEL HÜKÜMLER</h3>
      <p>
        5.1. Müşteri, Papyon’a ait internet sitesinde sözleşme konusu ürünün temel nitelikleri, satış fiyatı ve ödeme
        şekli ile teslimata ilişkin ön bilgileri okuyup, bilgi sahibi olduğunu, elektronik ortamda gerekli teyidi
        verdiğini kabul, beyan ve taahhüt eder.
      </p>
      <p>
        5.2. Müşteri, Teslimat ve İade süreçlerine ilişkin ön bilgilendirmeyi okuduğunu, mesafeli satış sözleşmesinin
        kurulmasından evvel, siparişi verilen ürünlere ait temel özellikleri, ürünlerin vergiler dâhil fiyatını, ödeme
        ve teslimat bilgilerini de doğru ve eksiksiz olarak edindiğini kabul, beyan ve taahhüt eder.
      </p>
      <p>
        5.3. Papyon, Sözleşme konusu her bir ürünü, 55 dakikayı aşmamak kaydı ile müşterinin konakladığı yer personeline
        yahut Papyon personeli aracılığı ile direkt müşteriye teslim edeceğini kabul, beyan ve taahhüt eder. Her bir
        sipariş özelinde maksimum %12.5 servis ücreti müşteri tarafından ödenecektir. Bu süre içinde ürün eksiksiz
        şekilde teslim edilmez ise, müşteri sözleşmeyi sona erdirebilir, iade yahut yeni ürün süreci başlatmak üzere
        Papyon’a bildirebilir.
      </p>
      <p>
        5.4. Papyon, sözleşme konusu ürünü eksiksiz, siparişte belirtilen niteliklere uygun ve varsa işin gereği olan
        bilgi ve belgeler ile teslim etmeyi, her türlü ayıptan arî olarak yasal mevzuat gereklerine göre sağlam,
        standartlara uygun bir şekilde işi doğruluk ve dürüstlük esasları dâhilinde ifa etmeyi, hizmet kalitesini
        koruyup yükseltmeyi, işin ifası sırasında gerekli dikkat ve özeni göstermeyi, ihtiyat ve öngörü ile hareket
        etmeyi kabul, beyan ve taahhüt eder.
      </p>
      <p>
        5.5. Müşteri tarafından satın alınan mal veya hizmetler Papyon’un öngöremeyeceği mücbir sebeplerin meydana
        gelmesi halinde süresinde teslim edilemez ise, durum müşteriye bildirilir. Papyon bu durumu öğrendiği an
        itibarıyla müşteriye gerekli bildirimde bulunacağını kabul, beyan ve taahhüt eder. Müşteri, siparişin iptalini,
        siparişin değiştirilmesini veya engel ortadan kalkana dek teslimatın ertelenmesini talep edebilir.
      </p>
      <p>
        Papyon, siparişin iptali halinde toplam bedeli müşteriye iade etmeyi kabul, beyan ve taahhüt eder. Müşteri
        siparişi iptal ederse; ödemeyi nakit ile yapmış ise iptalinden itibaren 3 gün içinde kendisine nakden bu ücret
        ödenir. Müşteri, ödemeyi kredi kartı ile yapmış ise ve iptal ederse, bu iptalden itibaren yine 3 gün içinde ürün
        bedeli bankaya iade edilir, ancak bankanın alıcının hesabına 2 hafta içerisinde aktarması olasıdır.
      </p>
      <p>
        5.6. Müşteri, satın aldığı mal veya hizmetlerin bedelini ödemez veya banka kayıtlarında iptal ederse, Papyon’un
        teslim yükümlülüğünün sona ereceğini kabul, beyan ve taahhüt eder.
      </p>
      <p>
        5.7. Müşteri, ödeme işlemlerini kredi kartı ile yaptığı durumda temerrüde düştüğü takdirde, kart sahibi banka
        ile arasındaki kredi kartı sözleşmesi çerçevesinde faiz ödeyeceğini ve bankaya karşı sorumlu olacağını kabul,
        beyan ve taahhüt eder. Bu durumda ilgili banka hukuki yollara başvurabilir; doğacak masrafları ve vekâlet
        ücretini müşteriden talep edebilir ve her koşulda müşterinin borcundan dolayı temerrüde düşmesi halinde,
        müşteri, borcun gecikmeli ifasından dolayı Papyon’un uğradığı zarar ve ziyanını ödeyeceğini kabul eder.
      </p>

      <h3>MADDE 6 : CAYMA HAKKI</h3>
      <p>
        6.1. Sözleşme konusu ürün, 6502 sayılı Tüketicinin Korunması Hakkında Kanun esas alınarak hazırlanan Mesafeli
        Sözleşmelere Dair Yönetmeliğin madde 15/1(c) hükümleri doğrultusunda, çabuk bozulabilen veya son kullanma tarihi
        geçebilecek mal niteliğinde ise, tüketici’nin cayma hakkı bulunmamaktadır.
      </p>
      <p>
        6.2. Sözleşme konusu ürün/ürünlerin tüketicinin istekleri veya açıkça onun kişisel ihtiyaçları doğrultusunda
        hazırlanan, niteliği itibariyle geri gönderilmeye elverişli olmayan ve çabuk bozulma tehlikesi olan bir ürün ise
        veya tesliminden sonra ambalaj, bant, mühür, paket gibi koruyucu unsurları açılmış olan mallardan iadesi sağlık
        ve hijyen açısından uygun olmayanların teslimine ilişkin bir sözleşme söz konusu ise Tüketici ve Satıcı
        arasındaki hukuki ilişkiye Mesafeli Sözleşmeler Yönetmeliği hükümleri uygulanamaması sebebiyle cayma hakkı
        kullanılamayacaktır.
      </p>

      <h3>MADDE 7 : YETKİLİ MAHKEME</h3>
      <p>
        İşbu Sözleşme Türkiye Cumhuriyeti Kanunlarına tabidir. Sözleşme’nin ifasından doğabilecek her türlü uyuşmazlığın
        çözümünde İstanbul Merkez Mahkemeleri ve İcra Müdürlükleri yetkilidir.
      </p>

      <h3>MADDE 8 : YÜRÜRLÜK</h3>
      <p>
        Müşteri, site üzerinden verdiği siparişe ait ödemeyi gerçekleştirdiğinde işbu sözleşmenin tüm şartlarını kabul
        etmiş sayılır. Müşteri, siparişin gerçekleşmesi öncesinde işbu sözleşmenin sitede Müşteri tarafından okunup
        kabul edildiğine dair onay alacak şekilde gerekli yazılımsal düzenlemeleri yapmakla yükümlüdür.
      </p>
    </div>
  );
}
