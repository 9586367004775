import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useDelivery } from "_context/delivery/deliveryProvider";
import { usePayment } from "_context/payment/paymentProvider";
import { useCarts } from "_context/shoppingCart/cartProvider";
// import animationData from "./animation.json";
// import Lottie from "react-lottie";
// import "./style.scss";
import styles from "./style.module.scss";
import { Button } from "react-bootstrap";
import { useDiscount } from "_context/discount/discountProvider";
import { useUtmSource } from "_context/utmSource/uuidProvider";
import { useCategory } from "_context/category/provider";
import anim from "./animation.gif";

export default function Done() {
	const { t } = useTranslation();

	const { initial: dInitial } = useDelivery();
	const { initial: pInitial } = usePayment();
	const { initial: catInitial } = useCategory();
	const { initial: cInitial } = useCarts();
	const { setUserId, initialVisibledPopup } = useUtmSource();
	const discountState = useDiscount();

	// const defaultOptions = {
	// 	loop: true,
	// 	autoplay: true,
	// 	animationData: animationData,
	// 	rendererSettings: {
	// 		preserveAspectRatio: "xMidYMid slice",
	// 	},
	// };

	useEffect(() => {
		dInitial();
		pInitial();
		cInitial();
		catInitial();
		discountState.initial();
		setUserId();
		initialVisibledPopup();
	}, []);

	return (
		<>
			<div className={styles["blank-page"]}>
				<img className={styles.image} src={anim} />
				{/* <Lottie options={defaultOptions} height={400} width={400} /> */}
				<p className={styles["text"]}>{t("preparing")}</p>

				<Link to={"/"}>
					<Button variant="outline-success">{t("back-to-menu")}</Button>
				</Link>
			</div>
		</>
	);
}
