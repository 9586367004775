import { useMemo } from "react"
import styles from "../index.module.scss"
import { useTranslation } from "react-i18next";
import moment from "moment";
// import moment from "jalali-moment";


const TimeComponent = ({ data, value, onSelect, selectedDay, setting }) => {


    const { t, i18n } = useTranslation();
    const isTranslate = useMemo(() => {
        const objData = {};
        data?.translations?.forEach((x) => (objData[x?.language] = x));

        return objData;
    }, [data]);

    const textTranslate = isTranslate[i18n?.language];

    // function convertTimeTo24HourFormat(timeString) {
    //     const timeMoment = moment(timeString, 'hh:mm A');
    //     const time24Hour = timeMoment.format('HH:mm');
    //     console.log({ timeMoment: timeMoment.toString(), timeString });
    //     return time24Hour;
    // }

    function convertTimeTo24HourFormat(timeString) {
        if (!timeString) return;
        const timeMoment = moment(timeString, 'hh:mm A').locale("en");
        const hours = timeMoment.hours();
        const minutes = timeMoment.minutes();
        let time24Hour;
        if (timeString?.includes('PM') && hours < 12) {
            time24Hour = `${hours + 12}:${minutes}`;
        } else if (timeString?.includes('AM') && hours === 12) {
            time24Hour = `00:${minutes}`;
        } else {
            time24Hour = `${hours}:${minutes}`;
        }
        return time24Hour;
    }



    const timeData = useMemo(() => {

        if (!data) return [];

        const meloStartTime = convertTimeTo24HourFormat(data?.meloStartTime);
        const meloEndTime = convertTimeTo24HourFormat(data?.meloEndTime);
        if (!meloStartTime || !meloEndTime) return;


        let [startHour, startMin] = meloStartTime?.split(":");
        let [endHour] = meloEndTime?.split(":");

        if (+startMin > 0) startHour = +startHour + 1

        // console.log("* * * TimeComponent ", { meloStartTime, meloEndTime, setting });
        // console.log("* * * TimeComponent ", { startHour: +startHour, endHour: +endHour });

        const time = []

        for (let i = 6; i <= 24; i++) {
            if (setting?.isToday) {
                if ((setting?.min === 0 || setting?.min > i)) continue;
            }
            // if (min && min > i) continue;
            let clock = `${i < 10 ? '0' : ''}${i}:00`
            if (+startHour < i && +endHour >= i) time.push(clock);
        }

        if (selectedDay && !value && time?.length) onSelect(time[0])

        return time;

    }, [setting?.min, data, selectedDay])


    const handleSelectTime = (param) => {
        // console.log("* * * TimeComponent (handleSelectTime) ", { param });
        onSelect(param)
    }

    // console.log("* * * TimeComponent ", { data, timeData, value });

    return (
        <div className={styles.timeContainer}>
            <ul className={styles.timeBox}>
                {
                    timeData?.map((x) => {
                        return (
                            <li key={x} className={[styles.timeRow, value == x && styles.active].join(" ")} onClick={() => handleSelectTime(x)}>
                                <div className={styles.time}>
                                    <span>
                                        {x}
                                    </span>

                                    <div className={styles.border}></div>
                                </div>
                                <div className={styles.contentBox}>

                                    <div className={[styles.checkedBox]}>
                                        <div className={[styles.check, styles.active].join(" ")}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                            </svg>
                                        </div>
                                    </div>

                                    <div className={styles.contentItem}>
                                        <div className={styles.icon}>
                                            <img src={data?.images} />
                                        </div>
                                        <div className={styles.text}>{textTranslate?.name || data?.name}</div>
                                    </div>
                                </div>
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    )
}

export default TimeComponent;