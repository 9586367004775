import { useMemo } from "react";
import giftIcon from "assets/offer/gif.png";
import boatIcon from "assets/tour/boat.png";
import { calcPercentNumber } from "utils/calculators";
import { popupNames, SYMBOLS_DATA_OBJECT } from "enumeration";
import { getHotel } from "api/api";
import { useQuery } from "react-query";
import { useUuid } from "_context/uuid/uuidProvider";
import { useCarts } from "_context/shoppingCart/cartProvider";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useDiscount } from "_context/discount/discountProvider";
import Decimal from "decimal.js";
import configs from "../../config";
import { useUtmSource } from "_context/utmSource/uuidProvider";
import styles from "./style.module.scss";

const SpecialOfferPercentageBar = () => {
	const { t, i18n } = useTranslation();
	const discountState = useDiscount();
	const { data: UtmData } = useUtmSource();

	const { data: uuid } = useUuid();
	const { data: hotel } = useQuery("hotel", () => getHotel(uuid), {
		enabled: !!uuid,
	});

	const isTranslate = useMemo(() => {
		if (!hotel?.campaignProduct?.translations) return;
		const objData = {};
		hotel?.campaignProduct?.translations?.forEach?.((x) => (objData[x?.language] = x));

		return objData;
	}, [hotel]);
	const { cart } = useCarts();
	const show = UtmData?.visibledPopup?.[popupNames?.specialOffer];
	if (!show) return <></>;
	const menuCurrency = hotel?.region?.menuCurrency;
	let currentCurrency = SYMBOLS_DATA_OBJECT[menuCurrency];
	let symbol = currentCurrency?.label;
	let showTotal = cart.withDiscountPrice || cart.totalWithService;

	// if (discountState?.data?.amount) showTotal = showTotal.sub(showTotal.div(100).mul(discountState?.data?.amount));
	showTotal = +(showTotal || 0);
	if (showTotal < 15 || isNaN(showTotal)) return <></>;

	let percent = +calcPercentNumber(25, showTotal);
	percent = percent > 100 ? 100 : percent;
	console.log("* * * SpecialOffer PercentageBar :", { percent });
	showTotal = Math.floor(showTotal);
	showTotal = showTotal >= 25 ? 25 : showTotal;
	const isComplete = percent >= 100;

	const textTranslate = isTranslate?.[i18n?.language];

	// hotel?.serviceCharge
	return (
		<div to={"/details"} className={[styles.wrap, show ? styles.show : styles.hide].join(" ")}>
			<div className={[styles.container].join(" ")}>
				<div className={styles.box}>
					<div className={styles.head}>
						<div className={styles.amount}>{`${showTotal || 0}${symbol}`}</div>
						<div className={styles.text}>
							{isComplete
								? t("specialOfferPercentageLabel", {
										name: textTranslate?.name || hotel?.campaignProduct?.name,
										price: `${hotel?.campaignProduct?.price}${symbol}`,
								  })
								: t("completedSpecialOfferBarText", {
										remain: `${Math.floor(25 - showTotal) || 0}${symbol}`,
										name: textTranslate?.name || hotel?.campaignProduct?.name,
										price: `${hotel?.campaignProduct?.price}${symbol}`,
								  })}
						</div>
						<div className={styles.amount}>{`25${symbol}`}</div>
					</div>
					<div className={styles.body}>
						<div className={styles.radialBox}>
							<div className={[styles.radialBoxActive, isComplete ? styles.isComplete : ""].join(" ")} style={{ width: `${percent}%` }}></div>
							{/* {
								<img
									className={styles.boatIcon}
									style={{ left: `${percent - 10 || 0}%`, visibility: !isComplete ? "visible" : "hidden" }}
									src={boatIcon}
									alt={"gift"}
								/>
							} */}
						</div>
						<div className={styles.giftBox}>
							<img className={styles.giftIcon} src={giftIcon} alt={"gift"} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SpecialOfferPercentageBar;
