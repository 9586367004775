import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import compareTranslate from "utils/compareTranslate";

const FieldText = ({ control, name, label, valid, register, errors, require, type, error, placeholder, setState, onChange, disables, end, mode, ...other }) => {
  const [show, setShow] = useState(false);
  let newRegister = register ? register(name) : {};

  const [t] = useTranslation();

  return (
    <Form.Group className="form-group ">
      <div className={"d-flex justify-content-between mb-1"}>
        {label ? (
          <label htmlFor={name}>
            {!disables?.translate?.label ? compareTranslate(t, label) : label}
            {require ? " *" : ""}
          </label>
        ) : (
          ""
        )}
      </div>
      <div className="input-wrapper d-flex">
        {control ?
          <Controller
            control={control}
            ref={newRegister}
            name={name}

            render={({ field: { onChange: change, value } }) => {
              function handleChange(params) {
                onChange?.(params);
                change(params);
              }
              if (mode == "textarea") return <textarea id={name} name={name} placeholder={!disables?.translate?.placeholder ? compareTranslate(t, placeholder || label) : placeholder || label} onChange={handleChange} value={value}{...other} />
              else return <Form.Control id={name} name={name} type={type === "password" ? (show ? "text" : "password") : type} placeholder={!disables?.translate?.placeholder ? compareTranslate(t, placeholder || label) : placeholder || label} onChange={handleChange} value={value}{...other} />
              // console.log({ onChange, value, options },'');
              // return <Select className="custom-selector" placeholder={label} options={newOptions} value={value} onChange={handleChange} {...other} />;
            }}
          /> :
          mode == "textarea" ? <textarea className="form-control" rows={4} id={name} name={name} placeholder={!disables?.translate?.placeholder ? compareTranslate(t, placeholder || label) : placeholder || label} onChange={onChange} {...other}  {...newRegister} />

            : <Form.Control id={name} name={name} type={type === "password" ? (show ? "text" : "password") : type} placeholder={!disables?.translate?.placeholder ? compareTranslate(t, placeholder || label) : placeholder || label} onChange={onChange} {...other}  {...newRegister} />
        }

        {type === "password" && <i className={`${show ? "mdi mdi-eye-off" : "mdi mdi-eye"} cursor-pointer text-muted eye-wrapper`} onClick={() => setShow(!show)}></i>}
        {end}
      </div>

      {errors?.[name] ? <div className={`error-text mt-1  `}>{!disables?.translate?.error ? compareTranslate(t, errors?.[name]?.message) : errors?.[name]?.message}</div> : ""}
      {error ? <div className={`error-text mt-1  `}>{!disables?.translate?.error ? compareTranslate(t, error?.message) : error?.message}</div> : ""}
    </Form.Group>
  );
};
export default FieldText;
