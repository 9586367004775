import { useEffect, useMemo, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import styles from "./index.module.scss";
import { changePriceFormat, numberWithCommas } from "utils/calculators";
import ModalLayout from "components/ModalLayout";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import moment from "jalali-moment";
import moment from "moment";
import { Alert } from "react-bootstrap";
import ModalBox from "reuse/ModalBox";
import DayComponent from "./Day";
import TimeComponent from "./Time";
// import 'moment/locale/fa'; // وارد کردن فایل زبان فارسی

const { useTranslation } = require("react-i18next");

function ModalMelo({ show, setShow, handleSubmit, data }) {
	// moment.locale("fa"); // تنظیم محلی به فارسی

	// console.log({ locales: moment.locale("fa") });
	const [selectedDay, setSelectedDay] = useState();
	const [selectedTime, setSelectedTime] = useState();
	const [setting, setSetting] = useState({
		min: false,
		isToday: false,
	});

	const { t, i18n } = useTranslation();

	// useEffect(() => {

	//   if (i18n.language) moment.locale(i18n.language)
	//   console.log({ language: i18n.language });

	// }, [i18n.language])

	let limitedPromotion = false;
	if ((data?.promotionCodes || data?.isCampaign) && data?.count == 1) limitedPromotion = true;

	useEffect(() => {
		if (!show) return;
		setSelectedTime();
		setSelectedDay();
		setSetting({});
	}, [show]);

	const handleSelectDay = (data) => {
		if (data?.disabled || !data) return;
		setSelectedDay(data);
		if (setting.isToday) setSelectedTime();
		let givenDate = moment(data.date).locale("en");
		let today = moment().locale("en");
		let isToday = givenDate.isSame(today, "day");
		let min = false;
		if (isToday) {
			let now = moment().locale("en");
			min = now.format("HH");
			let oneHourLater = now.add(2, "hours");
			let hourOfOneMinLater = +oneHourLater.format("mm");
			if (+hourOfOneMinLater > 0) min = +oneHourLater.startOf("hour").add(1, "hours").format("HH");
			else min = +oneHourLater.format("HH");
			console.log("* * * handleSelectDay * * *", { min, hourOfOneMinLater, isToday });
			setSelectedTime();
		}
		setSetting({
			isToday,
			min,
		});
	};

	const handleSelectTime = (data) => {
		setSelectedTime(data);
	};

	const onSubmit = () => {
		if (selectedDay && selectedTime) {
			let date = moment(selectedDay.date);
			let [hour] = selectedTime.split(":");
			date.hour(hour).minute(0).second(0);
			// console.log("* * * ModalMelo  submit* * *", { date: date.toISOString() });
			handleSubmit(data, date.toISOString());
		} else setShow(false);
	};

	let selectedDateFormat = useMemo(() => {
		try {
			if (selectedDay?.date) {
				let date = moment(selectedDay?.date);
				if (selectedTime) {
					let [hour] = selectedTime.split(":");
					date.hour(hour).minute(0).second(0);
					return date.format("DD, MMMM, dddd , HH:mm");
				} else return date.format("DD, MMMM, dddd");
			} else {
				let date = moment();
				return date.format("MMMM");
			}
		} catch (error) {
			return false;
		}
	});
	// let max = data?.meloMaxDays

	// const weekData = useMemo(() => {
	//   const date = []

	//   const today = moment();

	//   for (let i = 0; i < 7; i++) {
	//     const currentDate = today.clone().add(i, 'days');

	//     const dayOfWeek = currentDate.format('ddd');
	//     const dayOfMonth = currentDate.date();
	//     const month = currentDate.format('MMMM');

	//     date.push({
	//       label: dayOfWeek,
	//       number: dayOfMonth,
	//       month: month,
	//       date: currentDate.toString(),
	//       isMax: i == max,
	//       disabled: i > max
	//     });
	//   }
	//   if (!value && date?.length) onSelect(date[0])
	//   return date;
	// })

	// function convertTimeTo24HourFormat(timeString) {
	//   if (!timeString) return;
	//   const timeMoment = moment(timeString, 'hh:mm A').locale("en");
	//   const hours = timeMoment.hours();
	//   const minutes = timeMoment.minutes();
	//   let time24Hour;
	//   if (timeString?.includes('PM') && hours < 12) {
	//     time24Hour = `${hours + 12}:${minutes}`;
	//   } else if (timeString?.includes('AM') && hours === 12) {
	//     time24Hour = `00:${minutes}`;
	//   } else {
	//     time24Hour = `${hours}:${minutes}`;
	//   }
	//   return time24Hour;
	// }
	// const timeData = useMemo(() => {

	//   if (!data) return [];

	//   const meloStartTime = convertTimeTo24HourFormat(data?.meloStartTime);
	//   const meloEndTime = convertTimeTo24HourFormat(data?.meloEndTime);
	//   if (!meloStartTime || !meloEndTime) return;

	//   let [startHour, startMin] = meloStartTime?.split(":");
	//   let [endHour] = meloEndTime?.split(":");

	//   if (+startMin > 0) startHour = +startHour + 1

	//   // let date = moment().locale("en");
	//   // date.hour(startHour).minute(startMin).second(0);

	//   // let oneHourLater = moment(date).add(1, 'hours');

	//   // console.log("* * * TimeComponent ", { oneHourLater: oneHourLater.toString(), date: date.toString() });
	//   console.log("* * * TimeComponent ", { min, meloStartTime, meloEndTime, });
	//   console.log("* * * TimeComponent ", { startHour: +startHour, endHour: +endHour });

	//   const time = []

	//   for (let i = 6; i <= 24; i++) {
	//     if (min && min > i) continue;
	//     let clock = `${i < 10 ? '0' : ''}${i}:00`
	//     if (+startHour < i && +endHour >= i) time.push(clock);
	//   }

	//   if (!value && time?.length) onSelect(time[0])

	//   return time;

	// }, [min, data, selectedDay])

	console.log("* * * ModalMelo * * *", { data, setting, selectedDay, selectedTime });

	return (
		<>
			<ModalBox
				{...{
					showModal: show,
					onHideModal: () => {
						setShow();
					},
				}}
				fade={"bottom"}>
				<div className={[styles.modalBody, styles.fade].join(" ")}>
					<div className={styles.head}>
						<div className={styles.line}></div>

						<div className={styles.headline}>{t("AppointmentDaySelection")}</div>

						<DayComponent {...{ onSelect: handleSelectDay, value: selectedDay, max: data?.meloMaxDays }} />

						<div className={["d-flex justify-content-between align-items-center p-2 pb-0", styles.footer].join(" ")}>
							{/* <div className={["d-flex justify-content-between align-items-center p-2 ", styles.staticFooter].join(" ")}> */}

							{
								<div className={styles.selectedDate}>
									{/* 17,March, Saturday */}
									{selectedDateFormat || "-"}
								</div>
							}

							<Button onClick={onSubmit} color="danger" variant="danger" className={styles.acceptButton} disabled={!selectedDay || !selectedTime}>
								{t("append")}
							</Button>
							{/* </div> */}
						</div>
					</div>

					<TimeComponent {...{ onSelect: handleSelectTime, value: selectedTime, data, selectedDay, setting, min: setting?.min }} />
				</div>
			</ModalBox>
		</>
	);
}

export default ModalMelo;
