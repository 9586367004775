import { useDiscount } from "_context/discount/discountProvider";
import { getDiscount } from "api/api";
import { useMutation } from "react-query";
import styles from "./index.module.scss";
import FieldText from "components/FieldText";
import { fieldNames, validation } from "./validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { Button, Form } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import Alert from "react-bootstrap/Alert";
import { useEffect, useState } from "react";
import { useUtmSource } from "_context/utmSource/uuidProvider";
import { useNavigate } from "react-router-dom";

const NetworkDry = ({ toggle }) => {
	const { t, i18n } = useTranslation();
	const { data: utm, setNetworkDry } = useUtmSource();
	const navigate = useNavigate();
	const {
		register,
		handleSubmit,
		formState: { errors },
		getValues,
		setValue,
		control,
		reset,
		watch,
	} = useForm({
		resolver: yupResolver(validation()),
		mode: "onSubmit",
	});

	const onSubmit = () => {
		const values = getValues();
		console.log({ values });
		setNetworkDry(values[fieldNames.accept]);
		if (values[fieldNames.accept]) navigate("/payment");
		toggle();
	};

	const descriptions = [
		t("networkDryDesc1"),
		t("networkDryDesc2"),
		t("networkDryDesc3"),
		t("networkDryDesc4"),
		t("networkDryDesc5"),
		t("networkDryDesc6"),
		t("networkDryDesc7"),
		t("networkDryDesc8"),
		t("networkDryDesc9"),
	];
	return (
		<div className={styles.box}>
			<form onSubmit={handleSubmit(onSubmit)} id={"form-container "} noValidate>
				{/* <Alert show={show} variant="success">
					<p>{t("discount-note")}</p>
				</Alert> */}
				<p>{t(`networkDrySubtitle`)}</p>
				<div className="d-flex">
					<p>
						<span className="font-weight-bold text-capitalize ">
							{t(`warning`)}
							{" : "}
						</span>
						<Trans i18nKey="networkDryWarning" components={{ underline: <span className="font-weight-bold text-decoration-underline" /> }} />

						{/* {t(`networkDryWarning`, { interpolation: { escapeValue: false } })} */}
					</p>
				</div>
				<div className={styles.descriptionContent}>
					{descriptions?.map((x, i) => (
						<p>{`${i + 1} . ${x}`}</p>
					))}
				</div>
				<Controller
					control={control}
					ref={register}
					name={fieldNames.accept}
					render={({ field: { onChange: change, ...field }, fieldState: { error } }) => {
						return (
							<div type={"checkbox"} className="d-flex align-items-center  py-2 mb-3">
								<Form.Check.Input
									style={{
										width: "15px",
										minWidth: "15px",
										height: "15px",
										marginRight: "10px",
									}}
									type={"checkbox"}
									isValid
									onChange={change}
									// value={delivery?.name}
									isSearchable={false} //
									id={fieldNames.accept}
									// {...field}
								/>
								<label htmlFor={fieldNames.accept} className="d-flex">
									<p className="m-0">{t("networkDryCheckedLabel")}</p>
								</label>
							</div>
						);
					}}
				/>
				{/* <FieldText
						require
						{...{
							name: fieldNames.code,
							register,
							placeholder: " ",
							label: "code",
							errors,
						}}
					/> */}

				<Button type="success" htmlType="submit" className={`btn btn-success btn-sm`}>
					{t("continue")}
				</Button>
			</form>
		</div>
	);
};

export default NetworkDry;
