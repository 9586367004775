import React from "react";
// import Modal from "react-modal";
import styles from "./style.module.scss";
import powered from "../../assets/powered.svg";
import { useTranslation } from "react-i18next";
import { getHotel } from "api/api";
import { useUuid } from "_context/uuid/uuidProvider";
import { useQuery } from "react-query";
import layer from "../../assets/special.png"
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const FullScreenOffOrderPopup = ({ isOpen, onRequestClose }) => {
  const { data: uuid } = useUuid();
  const { data, isError, error } = useQuery("hotel", () => getHotel(uuid), {
    enabled: !!uuid,
  });

  const { t, i18n } = useTranslation();

  const changeLanguageHandler = (language) => {
    i18n.changeLanguage(language);
    onRequestClose(); // Close the popup after language change
  };

  // const languageOptions = data?.languages?.map((x) => {

  //   return ({
  //     value: x.code,
  //     label: (
  //       <div className="langOptions">
  //         {x.image ? <img src={x.image} alt={x.name} className="languageOptionImage" /> : ""}
  //         {x.name}
  //       </div>
  //     ),
  //   })
  // });

  // const value = languageOptions?.find((x) => x.value === i18n.language)

  return (
    <Modal
      style={{
        top: "0",
        // alignItems: "center!important",
        // transform: "translate(0%, 20%)"
      }}
      // isOpen={isOpen}
      // onRequestClose={onRequestClose}
      show={isOpen}
      onHide={() => onRequestClose(false)}
      dialogClassName={styles.modal_dialog}
      aria-labelledby="example-custom-modal-styling-title"
      className={styles["overlay"]}
      contentClassName={styles["content"]}
    // overlayClassName={styles["overlay"]}
    // ariaHideApp={false}
    // size="lg"
    >
      <div className={styles["popup-content"]}>
        {/* <div className="mt-3">
          <h1 className={[styles["popup-title"], "bold"].join(" ")}>SPECIAL FOR YOU </h1>
        </div> */}


        <div className={[styles["box-container"], "my-4"].join(" ")}>
          <img src={layer} />
          {/* {data?.languages.map((language) => (
            <div
              className="box"
              onClick={() => changeLanguageHandler(language.code)}
              key={language.code}
            >
              <img src={language.image} alt={language.code} />
              <p>{language.name}</p>
            </div>
          ))} */}
        </div>

        {/* <div >
          <h2 className={styles["popup-title-2"]}>IN ALL PRODUCTS</h2>
        </div> */}

        <Button
          className={[styles["button"], "mt-3"].join(" ")}
          // variant="danger"
          onClick={() => onRequestClose(false)}
          size="md"
        >
          {t("orderNow")}
        </Button>
      </div>



    </Modal>
  );
};

export default FullScreenOffOrderPopup;
