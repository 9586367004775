import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function ErrorTime({ message, title, button, className }) {
  return (
    <section className>
      <div className="osahan-home-page">

        <div className="p-3 osahan-cart-item">

          <div className={[`bg-white mt-10 p-3 text-center rounded shadow`, className].join(" ")}>
            <h5 className="bold mb-2">{title}</h5>
            <img src="/assets/img/food-delivery.png" />
            <h5 className="bold mt-3">{message}</h5>
            {
              button ?
                <Link to={button?.link}>
                  <Button >
                    {
                      button.text
                    }
                  </Button>
                </Link>
                : ""
            }
          </div>
        </div>
      </div>
    </section>
  );
}
