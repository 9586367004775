import { getHotel } from "api/api";
import BottomNav from "components/bottomNav";
import { PAYMENT_DATA, UUID_KY } from "enumeration";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { Link, useSearchParams } from "react-router-dom";
import { numberWithCommas } from "utils/calculators";
import { useDelivery } from "_context/delivery/deliveryProvider";
import { usePayment } from "_context/payment/paymentProvider";
import { useCarts } from "_context/shoppingCart/cartProvider";
import { useUuid } from "_context/uuid/uuidProvider";
import animationData from "./animation.json";
import Lottie from "react-lottie";
// import "./style.scss";

export default function EmptyCart() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <>
      <div className="osahan-cart-item"
        style={{
          height: "calc(100vh - 360px)",
          overflow: "hidden",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        {/* <p className="text matop">Starting adding items</p> */}
        <Lottie options={defaultOptions} height={400} width={400} />
      </div>
    </>
  );
}
