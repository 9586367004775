
import { useEffect, useRef } from "react";
import styles from "./searchbar.module.scss"
import { useLocation, useNavigate } from "react-router-dom";
import RemoveNullObjectValue from "lib/RemoveNullObjectValue";
import useQueryString from "hooks/useQueryString";
import SearchBar from "reuse/SearchBar";

const SearchBarProduct = () => {

    const queryString = useQueryString()

    const inputInterval = useRef()
    const ref = useRef()
    const location = useLocation();

    const changeUrl = (params) => ({
        pathname: location.pathname,
        search: new URLSearchParams(RemoveNullObjectValue({ ...queryString, ...params })).toString(),
    });

    const navigate = useNavigate();
    const navigateUrl = (obj) => navigate(changeUrl({ ...obj, page: 1 }));

    const handleChange = (e) => {
        clearTimeout(inputInterval.current)
        const value = e.target.value;
        inputInterval.current = setTimeout(() => {
            navigateUrl({ search: value })
        }, 1000);
    }

    useEffect(() => {
        if (!queryString?.search && ref.current) ref.current.value = ""
    }, [location])

    const onKeyDown = (e) => {
        console.log({ e });
        // if (e.keyCode === 13) {
        //     handleChange(e)
        // }
    }
    console.log({ queryString });
    return (
        <div className={[styles.searchBarContainer, "px-2"].join(" ")}>

            <SearchBar inputProps={{ onKeyDown, reference: ref }} />
            {/* <div className="px-2">
                <input
                    placeholder="Search ..."
                    type="text"
                    //   className={`form-control ${!roomValid ? "border-danger" : "border-success"}`}
                    className={["form-control border-success", styles.input].join(" ")}
                    id="oda_no"
                    name="roomNumber"
                    onChange={handleChange}
                    ref={ref}
                    defaultValue={queryString?.search}
                //   value={delivery?.roomNumber}
                />
            </div> */}

        </div>
    )
}

export default SearchBarProduct;