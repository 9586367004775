import { memo, useCallback, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import OtherCard from "components/OtherCard";
import CategorySliderScss from "./CategorySlider.module.scss";
import Skeleton from "react-loading-skeleton";
import { useCarts } from "_context/shoppingCart/cartProvider";

const WrapperProduct = ({ other, translatedCategoryObject, symbol }) => {
	const parentRef = useRef();
	const location = useLocation();
	// const { addCount, cart, removeItem, addCart } = useCarts();

	// const handleAddCart = useCallback((param) => {
	//     addCart(param);
	// }, [addCart]);

	// const handleRemoveCart = useCallback((param) => {
	//     removeItem(param);
	// }, [removeItem]);
	// const handleAddCount = useCallback((param, count) => {
	//     addCount(param, count);
	// }, [addCount]);
	// useEffect(() => {
	//     if (!location.hash) return;
	//     if (!other?.length) return;
	//     let hash = location.hash.replace("#", "");
	//     const current = document.querySelector(`#list-parent #id-${hash}`);
	//     console.log({ hash, current });
	//     window.scrollTo({ left: 0, top: current?.offsetTop - 60, behavior: "smooth" })

	// }, [location, other])

	// console.log({ location });
	useEffect(() => {
		if (!other?.length) return;
		// Get all sections that have an ID defined
		const sections = parentRef.current.querySelectorAll(".scroll-item");
		// console.log({ sections });
		// Add an event listener listening for scroll

		function checkScrollEnd(params) {
			clearTimeout(window.scrollEndTimer);
			window.scrollEndTimer = setTimeout(navHighlighter, 100);
		}

		function navHighlighter() {
			let scrollY = window.scrollY;
			let isSelect = false;
			let timeOut;
			sections.forEach((current) => {
				const sectionHeight = current.offsetHeight;
				const sectionTop = current.offsetTop - 90;
				let sectionId = current.getAttribute("id").replace("id-", "");

				if (scrollY > sectionTop && scrollY <= sectionTop + sectionHeight) {
					isSelect = true;
					const parent = document.querySelector(`.navigation`);
					const item = document.querySelector(`.navigation .ck-${sectionId}`);
					if (!item?.classList?.contains?.(CategorySliderScss["active"])) {
						clearTimeout(timeOut);
						console.log({ item, sectionId, parent });
						item?.classList?.add(CategorySliderScss["active"]);
						parent?.scrollTo(item?.offsetLeft - parent.clientWidth / 2, 0);
					}
				} else {
					document.querySelector(`.navigation .ck-${sectionId}`)?.classList?.remove(CategorySliderScss["active"]);
				}
			});

			if (!isSelect) {
				const parent = document.querySelector(`.navigation`);
				const item = document.querySelector(`.navigation`)?.children?.[0];
				if (!item) return;
				if (!item?.classList?.contains?.(CategorySliderScss["active"])) {
					item?.classList?.add?.(CategorySliderScss["active"]);
					parent?.scrollTo({ left: item.offsetLeft - parent.clientWidth / 2, top: 0, behavior: "smooth" });
					console.log({ item });
				}
			}
		}
		window.addEventListener("scroll", checkScrollEnd);
		return () => window.removeEventListener("scroll", navHighlighter);
	}, [other]);

	// const renderItems = useCallback(() => {
	//     return (
	//         other?.length ? other :
	//             new Array(1).fill())?.map((y) => {
	//                 console.log("* * * product :", { product: y });

	//                 return (
	//                     <div className="scroll-item pt-5" id={`id-${y?.categoryData?.id}`}>
	//                         <div className="deliveryText mb-3" >
	//                             {y ?
	//                                 translatedCategoryObject[y.categoryData?.id] || y.categoryData?.name
	//                                 :
	//                                 <Skeleton width={100} />
	//                             }
	//                         </div>
	//                         <div className="row g-4 pb-4">
	//                             {(y?.items || new Array(5).fill()).map((x) => {
	//                                 const { product, id, ...other } = x || {};
	//                                 const params = {
	//                                     menuId: id,
	//                                     ...(product || {}),
	//                                     ...(other || {}),
	//                                 };
	//                                 const isToCart = cart?.object[params?.id];
	//                                 if (isToCart) {
	//                                     const shoppingItem = cart?.shoppingCart?.find(
	//                                         (o) => o?.data?.id === params?.id
	//                                     );
	//                                     // console.log(
	//                                     //     "Main DEBUG",
	//                                     //     cart,
	//                                     //     shoppingItem,
	//                                     //     "asdasdas2d"
	//                                     // );
	//                                     params["count"] = shoppingItem?.count;
	//                                 }

	//                                 return (
	//                                     <OtherCard
	//                                         key={id}
	//                                         handleRemoveCart={handleRemoveCart}
	//                                         handleAddCart={handleAddCart}
	//                                         addCount={handleAddCount}
	//                                         isToCart={isToCart}
	//                                         data={params}
	//                                         symbol={symbol}
	//                                         loading={!y}
	//                                     />
	//                                 );
	//                             })}
	//                         </div>
	//                     </div>
	//                 )
	//             })
	// }, [other, handleAddCart]);

	return (
		<div className="g-4 " ref={parentRef} id={"list-parent"}>
			{/* <div className="scroll-item pt-2" id={`id-${catData?.parent?.id}`}>
                <div className="deliveryText mb-3"  >
                    {translatedCategoryObject[catData?.parent?.id] || catData?.parent?.name}
                </div>
                <div className="row g-4 ">
                    {category.map((x) => {
                        const { product, id, ...other } = x;
                        const params = {
                            menuId: id,
                            ...product,
                            ...other,
                        };
                        const isToCart = cart?.object[params?.id];
                        if (isToCart) {
                            const shoppingItem = cart?.shoppingCart?.find(
                                (o) => o?.data?.id === params?.id
                            );
                            console.log(
                                "Main DEBUG",
                                cart,
                                shoppingItem,
                                "asdasdas2d"
                            );
                            params["count"] = shoppingItem?.count;
                        }

                        return (
                            <OtherCard
                                key={id}
                                handleRemoveCart={handleRemoveCart}
                                handleAddCart={handleAddCart}
                                isToCart={isToCart}
                                data={params}
                            />
                        );
                    })}
                </div>
            </div> */}

			{(other?.length ? other : new Array(10).fill())?.map((y) => {
				console.log("* * * product :", { product: y });

				return (
					<div className="scroll-item pt-5" id={`id-${y?.categoryData?.id}`}>
						<div className="deliveryText mb-3">{y ? translatedCategoryObject[y.categoryData?.id] || y.categoryData?.name : <Skeleton width={100} />}</div>
						<div className="row g-4 pb-4">
							{(
								y?.items ||
								// ?.slice(0, 2)
								new Array(5).fill()
							).map((x) => {
								const { id, ...other } = x || {};
								const params = {
									menuId: id,
									id,
									// ...(product || {}),
									...(other || {}),
								};
								// const isToCart = cart?.object[params?.id];
								// if (isToCart) {
								//     const shoppingItem = cart?.shoppingCart?.find(
								//         (o) => o?.data?.id === params?.id
								//     );
								//     // console.log(
								//     //     "Main DEBUG",
								//     //     cart,
								//     //     shoppingItem,
								//     //     "asdasdas2d"
								//     // );
								//     params["count"] = shoppingItem?.count;
								// }

								return (
									<OtherCard
										key={id}
										// handleRemoveCart={handleRemoveCart}
										// handleAddCart={handleAddCart}
										// addCount={handleAddCount}
										// isToCart={isToCart}
										data={params}
										symbol={symbol}
										loading={!y}
									/>
								);
							})}
						</div>
					</div>
				);
			})}

			{/* {renderItems()} */}
		</div>
	);
};

export default memo(WrapperProduct);
