import { PAYMENT_DATA_OBJECT } from "enumeration";
import React, { createContext, useState, useContext } from "react";
import { useEffect } from "react";

const PaymentContext = createContext();
export const usePayment = () => useContext(PaymentContext);

function getInitialState() {
  const notes = localStorage.getItem("payment");
  return notes
    ? JSON.parse(notes)
    : {
      paymentType: PAYMENT_DATA_OBJECT?.online?.value,
      note: "",
      currency: "",
    };
}

export default function PaymentProvider({ children }) {
  const [data, setData] = useState(getInitialState);

  useEffect(() => {
    localStorage.setItem("payment", JSON.stringify(data));
  }, [data]);

  const onChangeNote = (e) => {
    setData((x) => ({ ...x, [e.target.name]: e.target.value }));
  };

  const onSelect = (name, value) => {
    setData((x) => ({ ...x, [name]: value }));
  };
  const onSelectCurrency = (name, value) => {
    setData((x) => ({ ...x, [name]: value }));
  };

  const initial = () =>
    setData({
      paymentType: PAYMENT_DATA_OBJECT?.card?.value,
      note: "",
    });

  return (
    <PaymentContext.Provider
      value={{ data, onChangeNote, onSelect, onSelectCurrency, initial }}
    >
      {children}
    </PaymentContext.Provider>
  );
}
