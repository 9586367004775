import React, { useState, useEffect } from "react";
import { useLocation, Link, useSearchParams } from "react-router-dom";
import { getChildCategories } from "../../api/api";
import { useQuery } from "react-query";
import { Routes, Route, useParams } from "react-router-dom";
import { UUID_KY } from "enumeration";
import BottomNav from "components/bottomNav";
import { Button, Spinner } from "react-bootstrap";
import ErrorTime from "components/ErrorTime";
import { useUuid } from "_context/uuid/uuidProvider";
import { useCategory } from "_context/category/provider";
import { useTranslation } from "react-i18next";
import { pageView } from "../../GTM";

const ChildCategory = (props) => {
  // const uuid = "e3a427f6-7821-4f2b-be22-012effb8de6b";
  let [searchParams] = useSearchParams();
  const { data: uuid } = useUuid();
  const { data: catData, setChild } = useCategory();
  const { t, i18n } = useTranslation();

  let { id } = useParams();

  // console.log({ catData });
  // const uuid = searchParams.get(UUID_KY)
  const {
    data: { data } = {},
    isError,
    error,
    isLoading,
  } = useQuery("childCategories", () => getChildCategories(uuid, id));

  const titleTranslateObject = catData?.parent?.translations?.reduce((obj, x) => {
    obj[x.language] = x;
    return obj;
  }, {});

  const isTitleTranslate = titleTranslateObject?.[i18n?.language];

  console.log({ data, error, isTitleTranslate });

  useEffect(() => {
    pageView(window?.location?.pathname);
  }, []);

  return (
    <>
      {!isLoading && !data?.length ? (
        <ErrorTime message={t("dataNotFound")} />
      ) : (
        <section className="category">
          <div className="container-fluid">
            <div className="category-title text-start px-2 d-flex">
              <div className="text-white font-weight-bold">
                <Link to={"/"}>
                  <Button variant="danger">
                    {" "}
                    <i className="fa-solid fa-angle-left" /> {t("back")}
                  </Button>
                </Link>
              </div>

              <div className="ml-auto">{isTitleTranslate?.name || catData?.parent?.name}</div>
            </div>
            {isLoading ? (
              <div className="text-center">
                {" "}
                <Spinner animation="border" />
              </div>
            ) : isError ? (
              <ErrorTime message={error?.response?.data?.message} />
            ) : (
              <div className="row px-2   g-4">
                {data?.map?.((data) => {
                  const isTranslate = data?.translations?.reduce((obj, x) => {
                    obj[x.language] = x;
                    return obj;
                  }, {});
                  const textTranslate = isTranslate?.[i18n?.language];
                  return (
                    <div className="col-6 col-sm-6 col-md-3">
                      <Link
                        to={{
                          pathname: `/product/${data.id}`,
                        }}
                        onClick={() => {
                          // if (data?.children) {
                          //   setChild(data);
                          //   console.log("setChild ===>", data);
                          // } else {
                          //   setChild(false);
                          //   console.log("setChild", false);
                          // }
                          setChild(data);
                          console.log("datas =====>", data);
                        }}
                      >
                        <div className="card h-100">
                          <img src={data.image} className="card-img-top" alt="..." />
                          <div className="card-body color-1">
                            <h5 className="card-title">{textTranslate?.name || data.name}</h5>
                          </div>
                        </div>
                      </Link>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          <BottomNav />
        </section>
      )}
    </>
  );
};

export default ChildCategory;
