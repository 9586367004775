const baseStrings = {
	translation: {
		home: "خانه",
		menu: "منو",
		opportunity: " فرصت ",
		opportunityProducts: "محصولات فرصت ",
		payment: "پرداخت",
		back: "Back",
		yourName: "اسم شما ",
		roomNumber: "شماره اتاق ",
		deliveryInfo: "اطلاعات تحویل ",
		yourDeliveryInfo: "اطلاعات تحویل شما",
		paymentInfo: "اطلاعات پرداخت ",
		productTotal: "مجموع محصول ",
		serviceFee: "هزینه خدمات ",
		total: "جمع",
		online: "برخط",
		card: "کارت اعتباری هنگام تحویل",
		myCart: "کارت من",
		cash: "پول نقد ",
		note: "توجه داشته باشید ",
		delivery: "تحویل",
		payment: "پرداخت",
		orderNote: "یادداشت سفارش خود را وارد کنید ",
		completeOrder: "سفارش کامل ",
		enjoyYourMeal: "نوش جان!",
		yourOrderReceived: "سفارش شما دریافت شد",
		// completeOrder: "سفارش شما تکمیل شده است",
		hello: "سلام",
		uuiError: "فقط با اسکن کد qr می توانید وارد شوید ",
		successful: "موفقیت آمیز ",
		productAddToCart: "محصول به سبد خرید اضافه شد!",
		addToBasket: "به سبد اضافه کن",
		removeFromBasket: "حذف از سبد",
		productTitle: "عنوان محصول",
		itemNotFound: "مورد یافت نشد",
		completeTheOrder: "تکمیل سفارش",
		selectThePayment: "پرداخت را انتخاب کنید",
		paymentType: "نوع پرداخت",
		orderLimitAuthor: "سفارش محدود نویسنده {{author}} {{currency}}",
		orderLimit: "حد سفارش",
		dataNotFound: "داده یافت نشد",
		confirmOrder: "برای تکمیل سفارش فشار دهید",
		exchangeInfo: "اگر با ارز خارجی پرداخت کنید، تبدیل شما به لیره ترکی انجام می‌شود.",
		continue: "ادامه",
		cashCurrency: "واحد پول را انتخاب کنید",
		other: "سایر کتگوری ها",
		// preparing: "سفارش شما در حال آماده شدن است نوش جان...",
		preparing: "ممنون! سفارش شما را دریافت کرده‌ایم. لطفاً فراموش نکنید صندوق ورودی خود را برای جزئیات بررسی کنید.",

		notValid: "نام و شماره اتاق را وارد کنید",

		searchHome: "...جستجو",
		notfound: "داده یافت نشد",

		searchAuthor: "جستجوی {{author}}",
		useDiscountCode: "از کد تخفیف خود استفاده کنید",
		discount: "تخفیف",
		discountAuthor: "تخفیف ({{author}})",
		products: "محصولات",
		append: "افزودن",
		code: "کد",
		checkAuthor: "چک {{author}}",
		"discount-note":
			"شما می توانید کد تخفیف خود را از این بخش بررسی کرده و در صورت لزوم از آن استفاده کنیدYou can check your discount code from this section and use it if necessary",
		"discount-code-details": "کدی که ارائه کردید {{code}} است. نام این کد تخفیف {{name}} است و دارای ارزش {{amount}}% تخفیف می باشد",
		"back-to-menu": "بازگشت به منو",
		bankErrorTitle: "پرداخت انجام نشد",
		bankErrorDescription: "مشکلی در پرداخت وجود دارد. لطفاً اطلاعات کارت خود را بررسی کنید و دوباره امتحان کنید",
		minimumCart: "حداقل : {{author}}",
		orderNow: "اکنون سفارش دهید",
		AppointmentDaySelection: "انتخاب روز قرار",
		paymentText: "پرداخت نقدی برای سفارشات پذیرفته نمی شود",
		baklavaSpecialOrderPopup: "هدیه باقلوا برای سفارشات خاص شما",
		baklavaTitle: "سنتی را بچشید",
		baklavaDescFirst: "لذت را تجربه کن",
		baklavaDescSecond: "باقلوای رایگان",
		baklavaDescThird: "با هر سفارشی که امروز می دهید!",
		apply: "استفاده",
		networkDryTitle: "اقدامات احتیاطی قبل از تحویل برای خشکشویی",
		networkDrySubtitle:
			"این اطلاعات به وضوح نکاتی را که مهمانان ما باید قبل از تحویل لباس‌ها برای خشکشویی رعایت کنند، بیان می‌کند. لطفاً با دقت مطالعه فرمایید.",
		warning: "هشدار",
		networkDryWarning:
			"محصولاتی که در طول روز تحویل می‌دهید، <underline>تا ساعت ۱۷:۳۰ روز بعد</underline> به شما تحویل داده می‌شوند. محصولات <underline>در همان روز تحویل داده نخواهند شد.</underline>",

		networkDryDesc1:
			"بررسی برچسب: برچسب‌های مراقبتی لباس‌هایتان را بررسی کنید. اطمینان حاصل کنید که برای خشکشویی مناسب هستند و دستورالعمل‌های شستشو را دنبال کنید. در صورتی که لباس‌هایی را که مناسب خشکشویی نیستند تحویل دهید، هرگونه مشکلات احتمالی که ممکن است به وجود آید، به عهده مصرف کننده خواهد بود.",
		networkDryDesc2:
			"اطلاع‌رسانی در مورد اشیاء قیمتی: از باقی گذاشتن اشیاء قیمتی (مانند ساعت، گردنبند، پول نقد و غیره) در جیب‌ها یا محفظه‌های مخفی لباس‌ها خودداری کنید.",
		networkDryDesc3:
			"زمان تحویل: لباس‌هایتان روز بعد از تحویل تا ساعت 5:30 بعدازظهر به شما تحویل داده می‌شود. در صورت نیاز فوری، لطفاً این موضوع را از قبل مشخص کنید. (در قسمت یادداشت‌های سفارش مشخص کنید.)",
		networkDryDesc4: "شرایط تحویل: از تحویل لباس‌ها با چوب لباسی‌ها و کاورهای شخصی خودداری کنید.",
		networkDryDesc5:
			"تشخیص لکه: اگر لباس‌هایتان لکه دارند، آن‌ها را شناسایی کرده و به خشکشویی اطلاع دهید. دانستن نوع لکه می‌تواند فرآیند تمیز کردن را مؤثرتر کند. (در قسمت یادداشت‌های سفارش مشخص کنید.)",
		networkDryDesc6:
			"دستورالعمل‌های ویژه: اگر هر بخشی از لباس‌هایتان حساس است یا نمی‌خواهید فرآیندی خاص اعمال شود، لطفاً این موضوع را به وضوح بیان کنید. (در قسمت یادداشت‌های سفارش مشخص کنید.)",
		networkDryDesc7:
			"بررسی سایش و آسیب: قبل از تحویل لباس‌ها، سایش، پارگی یا آسیب را بررسی کنید. به خشکشویی اطلاع دهید تا اقدامات احتیاطی بیشتری انجام شود. (در قسمت یادداشت‌های سفارش مشخص کنید.)",
		networkDryDesc8: "حذف لوازم جانبی: اگر لباس‌هایتان لوازم جانبی قابل جابجایی دارند (مانند کمربند، گل سینه و غیره)، قبل از تحویل آن‌ها را جدا کنید.",
		networkDryDesc9:
			"محو شدن رنگ: بررسی کنید که آیا لباس‌هایتان به محو شدن رنگ مستعد هستند و در صورت بروز، به خشکشویی اطلاع دهید. (در قسمت یادداشت‌های سفارش مشخص کنید.)",
		networkDryCheckedLabel: "من مطالب فوق را خوانده، فهمیده و با آنها موافقم.",
		tourTitle: "رایگان",
		tourDesc: "تور قایق سواری بوسفور برای هر سفارش ۶۰ یورویی!",
		tourButton: "جزئیات",
		detailsPage: {
			title:
				"با این کمپین، به محض رسیدن به حداقل مبلغ سفارش، شما یک تور قایق‌سواری در بسفر برنده خواهید شد. اکنون سفارش خود را ثبت کنید تا هدیه تور رایگان بسفر خود را دریافت کنید. جزئیات کمپین در زیر ارائه شده است.",
			desc1: "برای هر سفارش ۶۰ یورویی تنها یک بلیط رایگان ارائه می‌شود.",
			desc2: "برای برنده شدن این هدیه، مجموع سبد خرید شما باید حداقل ۶۰ یورو باشد.",
			desc3: "تور بسفر شامل منوی ثابت بدون الکل و نمایش‌های مختلف رقص که بازتاب‌دهنده فرهنگ ترکی است، می‌باشد.",
			desc4: "منو شامل یک غذای اصلی، پیش‌غذاها، دسر و نوشیدنی‌های غیرالکلی است.",
			desc5: "بلیط رایگان تور بسفر را می‌توان زودتر از روز بعد از ثبت سفارش استفاده کرد.",
			desc6: "تأییدیه رزرو و جزئیات تور از طریق ایمیل ارسال خواهد شد.",
			desc7: "برای دریافت بلیط رایگان تور بسفر، نام و نام خانوادگی مهمان، نام هتل، شماره اتاق و اطلاعات تماس معتبر لازم است.",
			desc8: "برای برنده شدن بلیط دوم، باید سفارش دیگری به مبلغ ۶۰ یورو ثبت کنید.",
		},

		tourPercentageLabel: "سفارش را تا {{amount}} تکمیل کنید تا تور رایگان بوسفور را دریافت کنید",
		completedTourBarText: "جزئیات تور به ایمیل شما ارسال خواهد شد",
		completedSpecialOfferBarText: "{{remain}} باقی‌مانده برای برنده شدن {{name}} به قیمت {{price}}!",
		specialOfferPercentageLabel: "شما {{name}} را به قیمت {{price}} برنده شدید!",
		cartItem: "محصول در سبد خرید",
		cartItems: "محصولات در سبد خرید",
		offers: "پیشنهادها",
		offer: "پیشنهاد",
	},
};

export const fa = baseStrings;
