import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import { Link, useLocation } from "react-router-dom";
import CategorySliderScss from "./CategorySlider.module.scss";
import { useEffect, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";

const CategorySlider = ({ data, translatedCategoryObject, currentCategory }) => {
	const [lock, setLock] = useState();

	const location = useLocation();
	const parentRef = useRef();

	useEffect(() => {
		if (!data?.length) return;
		if (lock) return; // alert("locked");
		let isSelect = false;

		parentRef?.current?.forEach?.((element) => {
			const isClass = element?.classList?.contains?.(CategorySliderScss["active"]);
			if (isClass) isSelect = true;
		});

		if (!isSelect) {
			// const defaultSelect = parentRef.current.children[0]
			// defaultSelect?.classList?.add?.(CategorySliderScss["active"]);
			// console.log({ defaultSelect });
			handleClick(currentCategory.id);
		}
		setLock(true);
	}, [data]);
	const handleClick = (hash) => {
		// if (!location.hash) return;
		// if (!other?.length) return;
		// let hash = location.hash.replace("#", "");
		const current = document.querySelector(`#list-parent #id-${hash}`);
		console.log({ hash, current });
		window.scrollTo({ left: 0, top: current?.offsetTop - 60, behavior: "smooth" });
	};

	return (
		<div className={`${CategorySliderScss[`list-box`]} px-2 text-nowrap`}>
			<div className={CategorySliderScss[`list-container`]}>
				<ul className={`${CategorySliderScss[`list-wrap`]} navigation`} ref={parentRef}>
					{(data || new Array(5).fill())?.map((x) => {
						return (
							<li
								key={x?.name}
								className={
									`ck-${x?.id}`
									// [location?.hash == `#${x?.id}` && CategorySliderScss[`active`], `ck-${x?.id}`].join(" ")
								}
								onClick={() => {
									handleClick(x?.id);
								}}>
								{/* <a href={`#${x?.id}`}> */}
								{translatedCategoryObject?.[x?.id] || x?.name || <Skeleton width={50} />}
								{/* </a> */}
							</li>
						);
					})}
				</ul>
			</div>

			{/* <Swiper
                slidesPerView={2.5}
                spaceBetween={10}
                // pagination={{
                //     clickable: true,
                // }}
                // modules={[Pagination]}
                className="mySwiper"
                breakpoints={{
                    370: {
                        slidesPerView: 3.5,
                    },
                    768: {
                        slidesPerView: 3.5,
                    },
                    1024: {
                    },
                }}
            >
                {
                    data?.map((x) => {
                        return (
                            <SwiperSlide key={x.id} >{x?.name}</SwiperSlide>
                        )
                    })
                }
            </Swiper> */}
		</div>
	);
};

export default CategorySlider;
