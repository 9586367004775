export const GTM_ID = "GTM-TBBJL7C"; // Replace GTM-XXXXXXX with your container ID

export const pageView = (url) => {
  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push({
    event: "pageview",
    page: {
      path: url,
    },
  });
};
