/*eslint-disable */
// function localStorageSetItem({ param }) {

import Decimal from "decimal.js";
import { serviceFeeAmount } from "enumeration";

//   localStorage.setItem("basket", JSON.stringify(param));
// }
// =============================  ADD_PRODUCT
export function handleAddProduct({ state, data, meloAppointmentDate, ...props }) {
	let newDate = new Date();
	if (state.object[data.id]) return handleChangeCount({ state, cart: data });
	let shoppingCart = [...state.shoppingCart, { data, count: 1, time: newDate.toISOString(), meloAppointmentDate }];
	let returnData = {
		...state,
		shoppingCart,
	};

	return handleTotal({ state: returnData, ...props });
}
// =============================  ADD_SHOPPING_CART
export function handleAddShoppingCart({ state, newShoppingCart }) {
	let returnData = {
		...state,
		shoppingCart: newShoppingCart,
	};

	return handleTotal({ state: returnData });
}
// =============================  REMOVE_PRODUCT
export function handleRemoveProduct({ state, cart, ...props }) {
	let returnData;
	let newShoppingCart = state?.shoppingCart?.filter((x) => {
		if (x?.data?.id !== cart?.id) return x;
	});
	returnData = { ...state, shoppingCart: newShoppingCart };
	return handleTotal({ state: returnData, ...props });
}
// =============================  PRICE_INCREASE
export function handleChangeCount({ state, cart, count, ...props }) {
	// console.log("handleChangeCount", { state, cart, count });
	let returnData;
	let increaseOrderData = state.shoppingCart.map((x) => {
		if (x.data.id === (cart.id || cart?.data?.id)) {
			// console.log("count log", { state, cart, count });

			x.count = count ? count : count === 0 ? 1 : (x.count || 0) + 1;
		}
		return x;
	});
	returnData = { ...state, shoppingCart: increaseOrderData };

	return handleTotal({ state: returnData, ...props });
}
// =============================  TOTAL_GOODS
export function handleTotal({ state, ...props }) {
	let returnData;
	const total = () => {
		const reducer = (accumulator, currentValue) => {
			// console.log("* * * handleTotal :", accumulator, currentValue.count);

			return new Decimal(accumulator || 0).add(currentValue.count || 0)?.toString();
		};
		// accumulator + currentValue.count;
		if (state.shoppingCart.length > 0) {
			return state.shoppingCart.reduce(reducer, 0);
		}
	};
	returnData = { ...state, counts: total() };
	console.log("* * * handleTotal returnData:", { returnData, props });

	return handleTotalPrice({ state: returnData, ...props });
}
const fee = serviceFeeAmount;
export const calculateValueWithPercent = (totalValue, partialValue = serviceFeeAmount) => {
	return new Decimal(totalValue || 0).div(100).mul(partialValue);
	//  (totalValue / 100) * partialValue;
};
// =============================  TOTAL_PRICE
export function handleTotalPrice({ state, discount, serviceCharge }) {
	let returnData;

	let object = {};
	const total = () => {
		const reducer = (accumulator, currentValue) => {
			object[currentValue.data?.id] = currentValue.data;
			// console.log("* * * handleTotalPrice :", accumulator, currentValue.data.price, currentValue.count);
			return new Decimal(accumulator || 0).add(new Decimal(currentValue.data.price || 0).mul(currentValue.count || 0));
			// return accumulator + currentValue.data.price * currentValue.count;
		};
		if (state.shoppingCart.length > 0) {
			return state.shoppingCart.reduce(reducer, 0);
		}
	};

	let prices = new Decimal(total() || 0);
	let serviceFee = 0;
	let totalWithService = prices;
	let totalPrices = 0;
	let totalDiscount = prices;
	if (serviceCharge) {
		serviceFee = calculateValueWithPercent(prices, fee);
		totalWithService = totalWithService.add(serviceFee);
	}
	if (discount) {
		totalDiscount = totalWithService.div(100).mul(discount);
		totalPrices = totalWithService.sub(totalDiscount);
	}

	returnData = {
		...state,
		prices: +prices || 0,
		serviceFee: +serviceFee || 0,
		object,
		totalWithService: +totalWithService || 0,
		totalDiscount: +totalDiscount || 0,
		withDiscountPrice: +totalPrices || 0,
	};
	console.log("* * * handleTotalPrice returnData:", { returnData, discount, serviceCharge });

	// localStorageSetItem({ param: returnData });
	return returnData;
}
