import React from "react";

export default function Return() {
  return (
    <div className="container">
      <h2>TESLİMAT VE İADE KOŞULLARI</h2>

      <h4>A. Taraflar</h4>

      <p>
        a) www.room-services.net/ internet sitesi üzerinden hizmet veren “Gülbahar Mahallesi Oya Sk. Tümer Plaza Apt.
        No: 7/2 Şişli/İstanbul”​ adresinde mukim Papyon Otel İkram ve Catering Hizmetleri Anonim Şirketi​ (“Papyon”)
      </p>
      <p>
        b) Papyon’a ait internet sitesi üzerinden mal veya hizmetlere ilişkin sipariş talebi oluşturan kişi ("Müşteri")
        <br />
        <br />
        İşbu Teslimat ve İadeye İlişkin Kullanım Koşulları verilen siparişler için uygun düştüğü ölçüde uygulanır.{" "}
      </p>

      <h2>B. Genel Hususlar</h2>
      <p>
        1. Papyon Otel İkram ve Catering Hizmetleri Anonim Şirketi’ne ait (
        <a href="www.room-services.net">www.room-services.net</a>) kullanmakta olduğunuz web sitesi üzerinden elektronik
        ortamda sipariş verirken size sunulan teslimat ve iade süreçlerine ilişkin ön bilgilendirme formunu ve mesafeli
        satış sözleşmesini kabul etmeniz gerekmektedir.
      </p>
      <p>
        2. Müşteriler, satın aldıkları ürünün satış ve teslimi ile ilgili olarak 6502 sayılı Tüketicinin Korunması
        Hakkında Kanun ve Mesafeli Sözleşmeler Yönetmeliği (RG:27.11.2014/29188) hükümleri ile yürürlükteki diğer
        yasalara tabidir.
      </p>
      <p>3. Her bir sipariş özelinde maksimum %12.5 servis ücreti müşteri tarafından ödenecektir.</p>
      <p>
        4. Satın alınan her bir ürün, 55 dakikayı aşmamak kaydı ile müşterinin konakladığı yere teslim edilir. Bu süre
        içinde ürün eksiksiz şekilde teslim edilmez ise, müşteri sözleşmeyi sona erdirebilir, iade yahut yeni ürün
        süreci başlatmak üzere Papyon’a bildirebilir.
      </p>
      <p>5. Satın alınan ürün, eksiksiz ve siparişte belirtilen niteliklere uygun teslim edilmek zorundadır.</p>
      <p>
        6. Satın alınan ürünün tesliminin mücbir sebepler dahilinde imkansızlaşması durumunda, Papyon bu durumu
        öğrendiği an itibarıyla müşteriye gerekli bildirimde bulunmak zorundadır. Siparişin iptali halinde toplam bedel
        müşteriye iade edilmek zorundadır.
      </p>
      <p>
        7. Müşteri, satın aldığı mal veya hizmetlerin bedelini ödemez veya banka kayıtlarında iptal ederse, Papyon’un
        teslim yükümlülüğü sona erer.
      </p>
      <p>
        8. Sipariş teslim edildikten sonra, müşterinin ödeme yaptığı kredi kartının kartın hamili haricinde, yetkisiz
        kişiler tarafından haksız ve hukuka aykırı olarak kullanılması halinde 23.02.2006 tarihli 5464 say
      </p>

      <div>
        <p>
          9. Papyon’un öngöremeyeceği mücbir sebepler oluşursa ve sipariş süresinde teslim edilemez ise, durum müşteriye
          bildirilir.
        </p>
        <p>
          Müşteri, siparişin iptalini, siparişin değiştirilmesini veya engel ortadan kalkana dek teslimatın
          ertelenmesini talep edebilir. Müşteri siparişi iptal ederse; ödemeyi nakit ile yapmış ise iptalinden itibaren
          3 gün içinde kendisine nakden bu ücret ödenir. Müşteri, ödemeyi kredi kartı ile yapmış ise ve iptal ederse, bu
          iptalden itibaren yine 3 gün içinde ürün bedeli bankaya iade edilir, ancak bankanın alıcının hesabına 2 hafta
          içerisinde aktarması olasıdır.
        </p>
      </div>
      <div>
        <p>10. İptal ve iade süreçleri hakkında bildirim yapılacak iletişim bilgileri:</p>
        <p>ŞİRKET ADI/UNVANI: Papyon Otel İkram ve Catering Hizmetleri Anonim Şirketi</p>
        <p>ADRES: Gülbahar Mahallesi Oya Sk. Tümer Plaza Apt. No: 7/2 Şişli/İstanbul​</p>
        <p>E-POSTA: info@papyonkitchen.com</p>
        <p>TELEFON: 0 535 833 36 25</p>
      </div>
      <div>
        <p>
          11. Müşteri, Papyon’un sipariş platformu üzerinden verdiği siparişin ücretini, sipariş verirken seçtiği
          şekilde teslim esnasında ödeyebilir yahut online ödeme yöntemi kullanarak banka kartı veya kredi kartı ile
          anında online ödeme de yapabilir.
        </p>
      </div>
      <div>
        <p>
          12. Müşteri, ödeme işlemlerini kredi kartı ile yaptığı durumda temerrüde düştüğü takdirde, kart sahibi banka
          ile arasındaki kredi kartı sözleşmesi çerçevesinde faiz ödeyeceğini ve bankaya karşı sorumlu olacağını kabul,
          beyan ve taahhüt eder.
        </p>
        <p>
          Bu durumda ilgili banka hukuki yollara başvurabilir; doğacak masrafları ve vekâlet ücretini müşteriden talep
          edebilir ve her koşulda müşterinin borcundan dolayı temerrüde düşmesi halinde, müşteri, borcun gecikmeli
          ifasından dolayı Papyon’un uğradığı zarar ve ziyanını ödeyeceğini kabul eder.
        </p>
      </div>
      <div>
        <div>
          <p>
            9. Papyon’un öngöremeyeceği mücbir sebepler oluşursa ve sipariş süresinde teslim edilemez ise, durum
            müşteriye bildirilir.
          </p>
          <p>
            Müşteri, siparişin iptalini, siparişin değiştirilmesini veya engel ortadan kalkana dek teslimatın
            ertelenmesini talep edebilir. Müşteri siparişi iptal ederse; ödemeyi nakit ile yapmış ise iptalinden
            itibaren 3 gün içinde kendisine nakden bu ücret ödenir. Müşteri, ödemeyi kredi kartı ile yapmış ise ve iptal
            ederse, bu iptalden itibaren yine 3 gün içinde ürün bedeli bankaya iade edilir, ancak bankanın alıcının
            hesabına 2 hafta içerisinde aktarması olasıdır.
          </p>
        </div>
        <div>
          <p>10. İptal ve iade süreçleri hakkında bildirim yapılacak iletişim bilgileri:</p>
          <p>ŞİRKET ADI/UNVANI: Papyon Otel İkram ve Catering Hizmetleri Anonim Şirketi</p>
          <p>ADRES: Gülbahar Mahallesi Oya Sk. Tümer Plaza Apt. No: 7/2 Şişli/İstanbul​</p>
          <p>E-POSTA: info@papyonkitchen.com</p>
          <p>TELEFON: 0 535 833 36 25</p>
        </div>
        <div>
          <p>
            11. Müşteri, Papyon’un sipariş platformu üzerinden verdiği siparişin ücretini, sipariş verirken seçtiği
            şekilde teslim esnasında ödeyebilir yahut online ödeme yöntemi kullanarak banka kartı veya kredi kartı ile
            anında online ödeme de yapabilir.
          </p>
        </div>
        <div>
          <p>
            12. Müşteri, ödeme işlemlerini kredi kartı ile yaptığı durumda temerrüde düştüğü takdirde, kart sahibi banka
            ile arasındaki kredi kartı sözleşmesi çerçevesinde faiz ödeyeceğini ve bankaya karşı sorumlu olacağını
            kabul, beyan ve taahhüt eder.
          </p>
          <p>
            Bu durumda ilgili banka hukuki yollara başvurabilir; doğacak masrafları ve vekâlet ücretini müşteriden talep
            edebilir ve her koşulda müşterinin borcundan dolayı temerrüde düşmesi halinde, müşteri, borcun gecikmeli
            ifasından dolayı Papyon’un uğradığı zarar ve ziyanını ödeyeceğini kabul eder.
          </p>
        </div>
        <div>
          <p>
            13. İşbu Sözleşme Türkiye Cumhuriyeti Kanunlarına tabidir. Sözleşme’nin ifasından doğabilecek her türlü
            uyuşmazlığın çözümünde İstanbul Merkez Mahkemeleri ve İcra Müdürlükleri yetkilidir.
          </p>
        </div>
      </div>
    </div>
  );
}
